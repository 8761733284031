import React, { ChangeEvent, FC, useState } from 'react';
import { SHOP_ROLES } from '../../CONSTANTS';
import { Text, TextOnly } from '../../components/Text';
import { changeUserShopRole } from '../../libs/db-lib';
import { useUser } from '../../context/User';
import { toast } from 'react-toastify';

interface UserRoleProps {
  userProp: any
}

type UserRole = keyof typeof SHOP_ROLES;

const UserRole: FC<UserRoleProps> = ({userProp}) => {
  const { user, currentShop } = useUser();

  const [userRole, setUserRole] = useState<UserRole>(userProp.role);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const handleChangeUserRole = async (event: ChangeEvent<HTMLSelectElement>) => {
    const newUserRole = event.target.value;
    if (
      !currentShop?.shopID ||
      !userProp?.userID ||
      ![SHOP_ROLES.ADMIN, SHOP_ROLES.TECH].includes(newUserRole)
    ) return;

    setIsloading(true);
    const response = await changeUserShopRole(currentShop.shopID, userProp.userID, newUserRole);
    if (!response?.error) {
      setUserRole(newUserRole as UserRole)
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsloading(false);
  }

  return(
    userProp.role === SHOP_ROLES.OWNER ? (
      <span className="u-font-weight-500">{userProp.role}</span>
    ) : userProp.userID === user?.userID || ![SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER].includes(user?.shopRole as string) ?
      <span>{userProp.role}</span>
    : (
      !isLoading ?
        <div
          className={`c-select c-select--no-border c-select--wide u-padding-none`}
        >
          <select value={userRole} onChange={handleChangeUserRole}>
            <option value={SHOP_ROLES.TECH}>{SHOP_ROLES.TECH}</option>
            <option value={SHOP_ROLES.ADMIN}>{SHOP_ROLES.ADMIN}</option>
          </select>
        </div>
      : <Text tid='updating' />
    )
  )
};

export default UserRole;