import React, { Component } from 'react';
import { Text, TextOnly } from '../components/Text';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { DateTime } from '../components/DateTime';
import { getShopStats } from '../libs/db-lib';
import 'react-table/react-table.css';
import { Loading } from '../components/Loading';
import { BarWidget } from '../components/Reports/BarWidget';
import AlertModal from '../components/AlertModal';
import { StatsWidget } from '../components/Reports/StatsWidget';
import { SHOP_TYPES } from '../CONSTANTS';
import { toast } from 'react-toastify';
import LoaderButton from '../components/LoaderButton';

const WIDGET_TYPE = {
  BAR: 'BAR',
  NUMBER: 'NUMBER',
};

const ranges = getSpeedDial(new Date());
export class StatsAndTrends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      isLoadingNewRange: false,
      currentRange: 'thisMonth',
      statWidgets: null,
      isLoadingStats: true,
    };
  }

  async componentDidMount() {
    if (this.props.currentShop.SHOP_TYPES === SHOP_TYPES.STANDARD) {
      toast.error(TextOnly('upgradeForStatsAndTrends'), {
        containerId: 'standard',
        autoClose: false,
      });
      this.props.history.push('/myActivity');
    }

    try {
      const { currentRange } = this.state;
      const timeGranularity = ranges[currentRange].timeGranularity;
      const timeSpecification = ranges[currentRange].timeSpecification;
      const statWidgets = await getShopStats(
        this.props.currentShop.shopID,
        timeGranularity,
        timeSpecification
      );

      this.setState({
        statWidgets,
        isLoadingStats: false,
      });
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
        isLoadingStats: false,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentRange !== this.state.currentRange) {
      this.setState({
        isLoadingNewRange: true,
      });
      const { currentRange } = this.state;
      const timeGranularity = ranges[currentRange].timeGranularity;
      const timeSpecification = ranges[currentRange].timeSpecification;
      try {
        const statWidgets = await getShopStats(
          this.props.currentShop.shopID,
          timeGranularity,
          timeSpecification
        );
        this.setState({
          statWidgets,
          isLoadingNewRange: false,
        });
      } catch (e) {
        this.setState({
          alertMessage: e.message,
          showModal: true,
          isLoadingNewRange: false,
        });
      }
    }
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleChangeRange = (range) => {
    this.setState({ currentRange: range, isLoading: true });
  };

  render() {
    if (this.state.statWidgets === null || this.state.isLoadingStats)
      return <Loading />;

    return (
      <>
        <div className="c-box--outline u-margin-bottom-large">
          <h2 className="c-box__title u-padding-bottom">
            <Text tid="dashboardControls" />
          </h2>
          <div className="l-flex">
            {Object.keys(ranges).map((range) => (
              <LoaderButton
                key={range}
                type="button"
                isLoading={
                  this.state.isLoadingNewRange &&
                  this.state.currentRange === range
                }
                onClick={this.handleChangeRange.bind(this, range)}
                text={<Text tid={range} />}
                loadingText={`${TextOnly(range)}`}
                className={`${
                  this.state.currentRange === range ? 'c-btn' : 'c-btn-outline'
                } u-margin-right`}
              />
            ))}
          </div>
        </div>

        <div className="l-grid-row-stats-trends u-margin-bottom-large u-margin-top-xlarge">
          {this.state.statWidgets?.length > 0
            ? this.state.statWidgets.map((widget) => {
                if (widget.type === WIDGET_TYPE.BAR) {
                  return (
                    <BarWidget
                      key={widget.title}
                      title={widget.title}
                      bars={widget.bars}
                      topOfRange={widget.topOfRange}
                    />
                  );
                } else if (widget.type === WIDGET_TYPE.NUMBER) {
                  return (
                    <StatsWidget key={widget.chartID} stats={widget.stats} />
                  );
                } else {
                  console.error(`WIDGET_ERROR: unknown type of ${widget.type}`);
                  return null;
                }
              })
            : null}
        </div>

        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />
      </>
    );
  }
}

export function getSpeedDial(now) {
  const today = new DateTime(now);
  const yesterday = new DateTime(now).subtract(1, 'day');
  const month = new DateTime(now);
  const lastMonth = new DateTime(now).subtract(1, 'month');
  const year = new DateTime(now).year();
  const lastYear = new DateTime(now).subtract(1, 'year').year();

  return {
    yesterday: {
      timeGranularity: 'DAY',
      timeSpecification: `${yesterday.format('YYYY_MM_DD')}`,
    },
    today: {
      timeGranularity: 'DAY',
      timeSpecification: `${today.format('YYYY_MM_DD')}`,
    },
    lastMonth: {
      timeGranularity: 'MONTH',
      timeSpecification: `${lastMonth.format('YYYY_MM')}`,
    },
    thisMonth: {
      timeGranularity: 'MONTH',
      timeSpecification: `${month.format('YYYY_MM')}`,
    },
    lastYear: {
      timeGranularity: 'YEAR',
      timeSpecification: `${lastYear}`,
    },
    thisYear: {
      timeGranularity: 'YEAR',
      timeSpecification: `${year}`,
    },
  };
}
