import React, { FC } from 'react';
import { Text } from '../../../components/Text';
import CrmPromoContent from './CrmPromoContent';

const ManageVehiclesPromo: FC<any> = (props) => {
  const handleRouteToPlusCrm = () => {
    props.history.push(`/upgradeToPlus/comparePlans`);
  }

  return (
    <>
      <div className="l-full-width">
        <div className="c-box c-box--promo-plus-crm">
          <div className="c-box__title">
            <Text tid="upgradeToAutoAuthPlusCrm" />
          </div>
          <div className="u-margin-none">
            <Text tid="upgradeNowAndStoreCustomerVehicles" />
          </div>
          <button
            className="c-btn-dark-red"
            onClick={handleRouteToPlusCrm}
          >
            <Text tid="upgradeNow" />
          </button>
        </div>
      </div>
      <CrmPromoContent promoType='manageVehicles' />
    </>
  );
};

export default ManageVehiclesPromo;