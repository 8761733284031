import React, { useEffect, useState } from "react";
import { Text, TextOnly } from "../Text";
import { Dialog } from "@reach/dialog";
import LoaderButton from "../LoaderButton";
import CompatibleTools from "./CompatibleTools";

interface RegionData {
  [key: string]: any;
}
interface CompatibleToolsModalProps {
  openButtonText: string;
  closeButtonText: string;
  whiteButton?: boolean;
  showOnlyRegion?: string;
  showShopRegion?: string;
  noCloseButton?: boolean;
  moveCloseButtonToTop?: boolean;
  chartData: RegionData[];
}

const CompatibleToolsModal: React.FC<CompatibleToolsModalProps> = (
{
  openButtonText,
  closeButtonText,
  showOnlyRegion = '',
  whiteButton = false,
  noCloseButton = false,
  moveCloseButtonToTop = false,
  chartData,
}) => {

  const [showChartModal, setShowChartModal] = useState<boolean>(false);

  const handleCloseChartModal = () => {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowChartModal(false);
    }, 250);
  };

  return (
    <>
      <div>
        <LoaderButton
          text={openButtonText}
          className={whiteButton ? 'c-btn-outline background-white' : ''}
          isLoading={false}
          loadingText={TextOnly('loading')}
          disabled={false}
          onClick={(e)=>{
            e.preventDefault();
            setShowChartModal(true);
          }}
        />
      </div>
      <Dialog
        isOpen={showChartModal}
        className="c-modal"
        onDismiss={()=>handleCloseChartModal()}
        aria-label="oemCompatibilityChart"
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={()=>handleCloseChartModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <div className="h1 c-modal__heading">
          {openButtonText}
        </div>

        <div className="c-modal__body">
        <CompatibleTools
          showOnlyRegion={showOnlyRegion}
          closeFunction={ noCloseButton ? undefined : () => handleCloseChartModal() }
          closeButtonText={closeButtonText}
          moveCloseButtonToTop={moveCloseButtonToTop}
          chartData={chartData}
        />
        </div>
      </Dialog>
    </>
  )
}

export default CompatibleToolsModal;