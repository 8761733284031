import React, { useEffect, useState } from 'react';
import { User, Shop } from '../types';
import { SHOP_ROLES, SHOP_TYPES } from '../CONSTANTS';
import { findShopById, getSavedShop, getUserData } from '../libs/utils-ts';
import { useLanguage } from '../libs/Language';
import config from '../config';
import { AA_ENV } from '../CONSTANTS';
import { FullStory } from '@fullstory/browser';
import { assignTechColors } from '../libs/utils';

export interface UserContextInterface {
  user: User | null;
  currentShop: Shop | null;
  error: { dataRetrieveError: string } | null;
  updateUser: (user: User | null) => void;
  updateCurrentShop: (shopId: string) => void;
  fetchUser: () => void;
  isEnterprise: boolean;
  isPlus: boolean;
  isStandard: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isTech: boolean;
}

// create the language context with default selected language
export const UserContext = React.createContext<UserContextInterface>({
  user: null,
  currentShop: null,
  error: null,
  updateUser: (user: User | null) => null,
  updateCurrentShop: (shopId: string) => null,
  fetchUser: () => null,
  isEnterprise: false,
  isPlus: false,
  isStandard: false,
  isOwner: false,
  isAdmin: false,
  isTech: false,
});

export function useUser() {
  return React.useContext(UserContext);
}

type UserProviderType = {
  children: JSX.Element;
};

export function UserProvider({ children }: UserProviderType) {
  const { userLanguage, userLanguageChange } = useLanguage();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<{ dataRetrieveError: string } | null>(
    null
  );
  const [currentShop, setCurrentShop] = React.useState<Shop | null>(null);

  // update current shop if user changes
  useEffect(() => {
    const savedShop = getSavedShop(user?.shops || []);
    if (savedShop?.shopUsers?.length) {
      savedShop.shopUsers = assignTechColors(savedShop.shopUsers)
    }
    setCurrentShop(savedShop);

    if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV && config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.EXP && user) {
      // Setup FullStory User Information
      FullStory('setIdentity',
        {
          uid: user.userID,
          properties: {
            displayName: user.firstName + ' ' + user.lastName,
            email: user.email,
            username: user.userName,
            env: config.AUTH_DIAG_ENVIRONMENT,
            shopID: savedShop?.shopID,
            shopName: savedShop?.shopName,
            shopType: savedShop?.shopType,
            regionCode: savedShop?.regionCode,
            oemIDs: savedShop?.oemIDs,
          }
        }
      );
    }

  }, [user]);

  const updateUser = (newUser: User | null) => {
    setUser(newUser);
  };

  const updateCurrentShop = (shopId: string) => {
    window.localStorage.setItem('CURRENT_SHOP', shopId);
    const savedShop = findShopById(user?.shops || [], shopId);
    if (savedShop?.shopUsers?.length) {
      savedShop.shopUsers = assignTechColors(savedShop.shopUsers)
    }
    setCurrentShop(savedShop);
  };

  const fetchUser = async (shopId?: string) => {
    const response = await getUserData();
    if (response?.dataRetrieveError) {
      window.console.log('Data Retrieval Error: ', response.dataRetrieveError);
      // @ts-ignore - Types of property 'dataRetrieveError' are incompatible
      setError(response);
    } else {
      const isLanguageDifferent = response?.user?.language !== userLanguage;
      if (isLanguageDifferent) {
        userLanguageChange(response?.user?.language);
      }
      if (shopId) {
        const savedShop = findShopById(response?.user?.shops || [], shopId);
        if (savedShop?.shopUsers?.length) {
          savedShop.shopUsers = assignTechColors(savedShop.shopUsers)
        }
        setCurrentShop(savedShop);
      }
      updateUser(response?.user || null);
    }
  };

  // shop type and shop role conditionals
  const isEnterprise = currentShop?.shopType === SHOP_TYPES.ENTERPRISE;
  const isPlus = currentShop?.shopType === SHOP_TYPES.PLUS;
  const isStandard = currentShop?.shopType === SHOP_TYPES.STANDARD;
  const isOwner = currentShop?.shopUserRole === SHOP_ROLES.OWNER;
  const isAdmin = currentShop?.shopUserRole === SHOP_ROLES.ADMIN;
  const isTech = currentShop?.shopUserRole === SHOP_ROLES.TECH;

  const provider = {
    user,
    currentShop,
    error,
    updateUser,
    fetchUser,
    updateCurrentShop,
    isEnterprise,
    isPlus,
    isStandard,
    isOwner,
    isAdmin,
    isTech,
  };

  return (
    <UserContext.Provider value={provider}>{children}</UserContext.Provider>
  );
}
