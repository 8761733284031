import React, { useState } from 'react';
import { User, Shop } from '../../types';
import { Text, TextOnly, LangDictKey } from '../../components/Text';
import CompatibleTools from "../../components/CompatibleTools/CompatibleTools";
import CompatibleToolsModal from '../../components/CompatibleTools/CompatibleToolsModal';
import { Dialog } from '@reach/dialog';
import ToolTip from '../../components/ToolTip';
import { toast } from 'react-toastify';
import LoaderButton from '../../components/LoaderButton';
import systemConfig from '../../config';
import AddTechCertification from './AddTechCertification';
import { PAYMENT_STATES, SHOP_TYPES } from '../../CONSTANTS';
import ConfirmModal from '../../components/ConfirmModal';
import { NavLink } from 'react-router-dom';
import NextPayment from './NextPayment';
import AlertModal from '../../components/AlertModal';
import SubEndingNotify from './SubEndingNotify';
import { useScreenSize } from '../../context/ScreenSize';
import dayjs from 'dayjs';

import {
  updateOEMRegions,
  addReinstateOEMRegions,
  addUsersToShopCapacity,
  downgradeToStandard,
  removeDowngradeToStandard,
  updateShopInfo,
  reinstateNonTerminatedShop,
} from '../../libs/db-lib';

interface RegionData {
  [key: string]: any;
}
interface AASubscriptionProps {
  oemRegions: { [key: string]: any}[];
  compatiblityChart: RegionData[];
  user: User;
  currentShop: Shop;
  config: any;
  fetchUser: any;
  className?: string;
}

const AASubscription = (props: AASubscriptionProps) => {

  const {
    user,
    currentShop,
    config,
    fetchUser,
    oemRegions,
    compatiblityChart,
    className,
  } = props;

  const { windowWidth, isMobile } = useScreenSize();

  const [oemIDsRemoved, setOemIDsRemoved] = useState(new Set());
  const [oemIDsAdded, setOemIDsAdded] = useState(new Set());
  const [showOEMRegionRemovalModal, setShowOEMRegionRemovalModal] = useState(false);
  const [showModalMO, setShowModalMO] = useState(false);
  const [showOEMRegionReinstateModal, setShowOEMRegionReinstateModal] = useState(false);
  const [isLoadingChangingOEMRegions, setIsLoadingChangingOEMRegions] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState<Partial<React.Component>>();
  const [showModal, setShowModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [numToAdd, setNumToAdd] = useState<string>('');
  const [isLoadingIncreaseMaxUsers, setIsLoadingIncreaseMaxUsers] = useState(false);
  const [isLoadingCancelSubscription, setIsLoadingCancelSubscription] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const [isLoadingDowngradeSubscription, setIsLoadingDowngradeSubscription] = useState(false);
  const [showDowngradeSubscriptionModal, setShowDowngradeSubscriptionModal] = useState(false);
  const [isLoadingCancelDowngradeSubscription, setIsLoadingCancelDowngradeSubscription] = useState(false);
  const [showDowngradingMessage, setShowDowngradingMessage] = useState(false);
  const [showCancelingMessage, setShowCancelingMessage] = useState(false);
  const [isLoadingReinstateShop, setIsLoadingReinstateShop] = useState(false);

  const isPlus = currentShop?.shopType === SHOP_TYPES.PLUS;

  const isMediumScreen = (windowWidth < 1410 && windowWidth >= 690);
  const isSmallScreen = windowWidth < 690 || isMobile;

  const regionCode = currentShop!.regionCode;
  const shopRegionName = regionCode && oemRegions ?
      oemRegions.find((or: any) => or.regionCode === currentShop.regionCode )?.regionName : '';
  const paymentState = user?.paymentState || '';

  let regions: { [key: string]: any} = {};
  const oemByRegion: { [key: string]: any} = {};
  let currentRegionName = '';
  const shopPaymentInfo = currentShop.shopPaymentInfo;

  if (oemRegions) {
    oemRegions.forEach((oemRegion: { [key: string]: any}) => {
      const regionName: string = oemRegion.regionName;
      if (oemRegion.regionCode === regionCode) {
        currentRegionName = oemRegion.regionName;
      }
      if (oemByRegion[regionName]) {
        oemByRegion[regionName].push(oemRegion);
        regions[regionName] = oemRegion.regionCode;
      } else {
        oemByRegion[regionName] = [oemRegion];
        regions[regionName] = oemRegion.regionCode;
      }
    })
  }

  const oemByRegionArraySorted = Object.keys(oemByRegion).sort();
  const removeOEMAllowed = (currentShop?.oemIDs!.length - (currentShop?.removedOemIDs?.length ?? 0)) > 1;
  const oemByRegionList: { [key: string]: any} = {};

  const oemRegionsToAdd: Partial<React.Component>[] = [];

  if (oemByRegionArraySorted.length) {
    oemByRegionArraySorted.forEach((regionKey) => {
      oemByRegionList[regions[regionKey]] = (
        <table className={"t-table"}>
          <tbody>
            {oemByRegion[regionKey].sort((a: any ,b: any)=> a.oemName > b.oemName ? 1 : -1).map((oemRegion: any) => {
              if (!currentShop?.oemIDs?.includes(oemRegion.oemID)) {
                return <tr key={oemRegion.oemID} style={{display: 'none'}}></tr>;
              } else {
                return (
                  <tr key={oemRegion.oemID}>
                    <td className="u-padding-left-none u-padding-right-none">
                      {removeOEMAllowed && !currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                        <a
                          className="c-btn__icon fal fa-circle-xmark color-btn-blue u-padding-left-none u-padding-right-small"
                          onClick={() => handleRemoveOEMChange(oemRegion.oemID)}
                        />
                        : ''
                      }
                    </td>
                    <td className="u-padding-left-none u-padding-right-none">
                      <label className="u-font-size-med u-padding-top-4th-rem">{oemRegion.oemName}
                        {oemRegion.countries ? <span className="u-font-weight-bold u-font-size-small u-margin-left-small u-text-warning">{'('+oemRegion.countries.join(', ')+' '+TextOnly('only')+')'}</span> : ''}
                      {oemRegion.hint ?
                        <ToolTip text={oemRegion.hint?.text ? oemRegion.hint?.text : oemRegion.hint} >
                          <span className="u-margin-left-small">
                            <i className={"c-btn__icon color-btn-blue u-font-size-small fal fa-question-circle"} />
                          </span>
                        </ToolTip>
                      : ''
                      }
                      </label>
                      {currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                        <label className="u-font-size-small"><Text tid="activeUntilRenewal" sub={{date: <>{shopPaymentInfo?.paidThroughDate}</>}}/></label>
                      : ''}
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      );
    });
  }

  if (oemRegions && oemIDsRemoved) {
    oemRegions.forEach((oemRegion: any) => {

      if (oemRegion.regionCode === currentShop?.regionCode && (currentShop?.removedOemIDs?.find((or) => or === oemRegion.oemID) ||
          currentShop?.oemIDs?.find((or) => or === oemRegion.oemID) === undefined)) {
        oemRegionsToAdd.push(
          <div
            key={oemRegion.oemID}
            className={`u-margin-top-large ${windowWidth > 1000 ? "u-margin-left-small" : ""}`}>
            <div className="l-flex-wrap u-align-center">
              <div className={"l-inline-flex"}>
                <input
                  id={oemRegion.oemID}
                  type="checkbox"
                  checked={oemIDsAdded.has(oemRegion.oemID)}
                  onChange={(e) => handleAddOEMChange(e)}
                  disabled={false}
                  className="u-margin-right-large"
                />
                <div className="l-flex-column">
                  <label className="c-field__label left-padding u-padding-bottom-none">
                    {oemRegion.oemName}{' '}
                    {oemRegion.countries ?
                      <span className="u-font-weight-bold u-font-size-small u-text-warning">{'('+oemRegion.countries.join(', ')+' '+TextOnly('only')+')'}</span> : ''}
                    {oemRegion.hint ?
                      <ToolTip text={oemRegion.hint?.text ? oemRegion.hint?.text : oemRegion.hint} >
                        <span className="u-margin-left-small">
                          <i className={"c-btn__icon color-btn-blue u-font-size-small fal fa-question-circle"} />
                        </span>
                      </ToolTip>
                    : ''
                    }
                  </label>
                  { currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                      <label className="u-font-size-small"><Text tid="expiresOn" sub={{date: <>{shopPaymentInfo?.paidThroughDate}</>}}/></label>
                    : ''
                  }
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }

  let oemRegionsToAddComponent;
  if (oemRegionsToAdd.length > 0) {
    oemRegionsToAddComponent =
      <>{oemRegionsToAdd}</>
  }

  const handleCancelSubscriptionButton = () => {
    setShowCancelSubscriptionModal(true);
  };

  const eligibleToDowngrade = () => {
    const paymentState = user?.paymentState || '';
    const suspendReason = currentShop?.suspendReason || '';

    return (currentShop?.shopUsers &&
            currentShop?.shopUsers?.length <= 5 &&
            currentShop?.userShopTools?.length <= 5 &&
            (paymentState !== 'SUSPENDED' || suspendReason !== "PAST_DUE") &&
            paymentState !== "PAST_DUE");
  }

  const handleDowngradeSubscriptionButton = () => {
    setShowDowngradeSubscriptionModal(true);
  };

  const handleCancelDowngradeSubscriptionButton = async () => {
    setIsLoadingCancelDowngradeSubscription(true);

    let result = await removeDowngradeToStandard(
      currentShop.shopID,
    );

    if (result.error) {
      toast.dismiss();
      toast.error(result.error, {
        containerId: 'standard',
      });

      setShowDowngradingMessage(false);
      setIsLoadingCancelSubscription(false);
    } else {
      const shopState = currentShop?.shopSubscriptionState;
      let message = "cancelDowngradeSuccessful";
      if (shopState === "TERMINATED" || shopState === "USER_CANCEL") {
        message = "downgradeSuccessful";
      }
      toast.success(TextOnly(message as LangDictKey), {
        containerId: 'standard',
      });

      setIsLoadingCancelDowngradeSubscription(false);

      await fetchUser();
    }
  }

  const handleReinstateShop = async () => {
    setIsLoadingReinstateShop(true);

    let result = await reinstateNonTerminatedShop(currentShop.shopID);

    if (result.error) {
      toast.dismiss();
      toast.error(result.error, {
        containerId: 'standard',
      });
      setIsLoadingReinstateShop(false);

    } else {
      const shopState = currentShop?.shopSubscriptionState;
      let message = "shopReinstated";
      toast.success(TextOnly(message as LangDictKey), {
        containerId: 'standard',
      });

      setIsLoadingReinstateShop(false);

      await fetchUser();
    }
  }

  const handleConfirmDowngradeSubscriptionModal = async () => {
    if(showDowngradingMessage) return;

    setShowDowngradingMessage(true);

    let result = await downgradeToStandard(
      currentShop.shopID,
    );

    if (result.error) {
      setAlertTitle(TextOnly('error'));
      setAlertMessage(result.error);
      setShowModal(true);
      setShowDowngradingMessage(false);
      setIsLoadingCancelSubscription(false);

      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        setShowDowngradeSubscriptionModal(false);
      }, 350);

    } else {
      const shopState = currentShop?.shopSubscriptionState;
      let message = "downgradeDelayedSuccessful";
      if (shopState === "TERMINATED" || shopState === "USER_CANCEL") {
        message = "downgradeSuccessful";
      }
      toast.success(TextOnly(message as LangDictKey), {
        containerId: 'standard',
      });

      await fetchUser();
      document.querySelector('.c-modal-slider')?.classList.add('closed');

      setShowCancelSubscriptionModal(false);
      setShowCancelingMessage(false);
      setIsLoadingCancelSubscription(false);
      setShowDowngradingMessage(false);
      setShowDowngradeSubscriptionModal(false);
    }
  };

  const handleCancelDowngradeSubscription = () => {
    const self = this;
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      setShowDowngradeSubscriptionModal(false);
    }, 350);
  };

  // Calculating the price per user rate coinciding with the current year's number of oems.
  // This function is being used for adding tools/users to a current subscription year.
  const calculatePerUserRate = (shop: Shop) => {
    if (config) {
      let perUserRate = config.prices?.[
        shop?.shopType.toLowerCase() + 'UserAddon'
      ];
      if (shop?.oemIDs!.length > 1) {
        perUserRate += config.prices?.oemRegionUserUpcharge * (shop.oemIDs!.length - 1);
      }
      return perUserRate;
    }
  }

  const handleConfirmCancelSubscriptionModal = async () => {
    if (showCancelingMessage) return;

    setShowCancelingMessage(true);

    let result = await updateShopInfo(
      currentShop.shopID,
      currentShop.shopName,
      'CANCEL'
    );

    if (result?.error) {
      setAlertTitle(TextOnly('error'));
      setAlertMessage(result.error);
      setShowModal(true);
      setShowCancelingMessage(false);
      setShowCancelSubscriptionModal(false);
      setIsLoadingCancelSubscription(false);
    } else {
      toast.success(TextOnly('shopSubscriptionCanceled'), {
        containerId: 'standard',
      });

      await fetchUser();

      setShowCancelSubscriptionModal(false);
      setShowCancelingMessage(false);
      setIsLoadingCancelSubscription(false);
    }
  };

  // Calculating the price per oem rate coinciding with the number of user/tool addons.
  // This function is being used for adding oems to a current subscription year.
  const calculatePerOemRate = (shop: Shop) => {
    if (config) {
      const baseUsers = config.shopBaseValues[shop.shopType.toLowerCase() + 'BaseUsers'];
      const userAddons = shop.shopMaxUsers > baseUsers
        ? shop.shopMaxUsers - baseUsers
        : 0;
      return (config.prices?.oemRegionAddon + (userAddons * config.prices?.oemRegionUserUpcharge));
    }
  }

  const perUserRate = calculatePerUserRate(props?.currentShop);
  const perOemRate = calculatePerOemRate(props?.currentShop);
  const userBasePrice = config?.prices[props?.currentShop.shopType.toLowerCase() + 'UserAddon'];
  const oemBasePrice = config?.prices.oemRegionAddon;
  const oemUserUpcharge = config?.prices.oemRegionUserUpcharge;
  const crmUserUpcharge = config?.prices.crmUserUpcharge;
  const perTechCertRate = config?.prices[props.currentShop.shopType.toLowerCase() + 'TechCertAddon'];
  const maxUsers =
    currentShop?.shopUserCap ||
    config?.shopMaxValues?.[
      props?.currentShop?.shopType.toLowerCase() + 'MaxUsers'
    ];
  const maxAddUsers = maxUsers - user?.shopMaxUsers;

  const calculateNumberToChargeToday = (shop: Shop, oemIDsSet: Set<any>) => {
    const numberAdded = oemIDsSet.size;
    const reinstatingOemIDs = shop.removedOemIDs ? shop.removedOemIDs.filter(oemID => oemIDsSet.has(oemID)).length : 0;
    return numberAdded - reinstatingOemIDs;
  };

  const isDowngradingAtRenewal = currentShop?.downgradeToStandardPending ? true : false;

  const renewalTier = (isDowngradingAtRenewal || !isPlus) ? 'AutoAuth STANDARD' : 'AutoAuth PLUS';

  const removalPriceChange = renewalTier === 'AutoAuth STANDARD'
  ? oemIDsRemoved.size * config?.prices?.oemRegionAddon
  : oemIDsRemoved.size * perOemRate;

  const chargeToday =
    currentShop ?
      calculateNumberToChargeToday(currentShop, oemIDsAdded) * perOemRate : 0;

  const oemRegionModalList: any[] = [];
  oemIDsRemoved.forEach((oemID) => {
    const oemRegionRec = oemRegions.find((oemRegion) => {
      return oemRegion?.oemID === oemID && oemRegion?.regionCode === currentShop?.regionCode;
    });
    oemRegionModalList.push(<li key={oemRegionRec?.oemID}>{ oemRegionRec?.oemName } {oemRegionRec?.hint ? oemRegionRec.hint?.text ? oemRegionRec.hint?.text : oemRegionRec.hint : ''}</li>);
  });

  const today = dayjs().format('YYYY-MM-DD')
  const expired = (shopPaymentInfo?.paidThroughDate || '1970-01-01') < today;
  const subEndDate = shopPaymentInfo?.paidThroughDate ? dayjs(shopPaymentInfo?.paidThroughDate).add(1, 'day').format('YYYY-MM-DD') : null;

  const reinstateOemRegionModalList: any[] = [];

  oemIDsAdded.forEach((oemID) => {
    const oemRegionRec = oemRegions.find((or) => {
      return or.oemID === oemID
    });
    reinstateOemRegionModalList.push(<li key={oemRegionRec?.oemID}>{ oemRegionRec?.oemName } {oemRegionRec?.hint ? oemRegionRec.hint?.text ? oemRegionRec.hint?.text : oemRegionRec.hint : ''}</li>);
  });

  let OEMAddPriceChangeStatement;
  if (chargeToday) {
    OEMAddPriceChangeStatement =
      <Text tid={"willBeChargedChangingOEMRegion"}
        sub={{chargeToday: <>{chargeToday}</>,
              amount: renewalTier !== 'AutoAuth STANDARD'
            ? <>{oemIDsAdded.size * perOemRate}</>
            : <>{oemIDsAdded.size * config?.prices?.oemRegionAddon}</> }
          }
      />
  } else {
    OEMAddPriceChangeStatement = <Text tid={"noChargeTodayAddedPriceOEMRegions"}
        sub={{amount: renewalTier !== 'AutoAuth STANDARD'
          ? <>{oemIDsAdded.size * perOemRate}</>
          : <>{oemIDsAdded.size * config?.prices?.oemRegionAddon}</> }
        }
      />
  }

  const handleRemoveOEMChange = (oemID: string) => {
    let newOemIDsRemoved = new Set(oemIDsRemoved);
    if (!newOemIDsRemoved.has(oemID)) {
      newOemIDsRemoved.add(oemID);
    }
    setOemIDsRemoved(newOemIDsRemoved);
    setShowOEMRegionRemovalModal(true);
  };

  const handleAddOEMChange = (event: any) => {
    let newOemIDsAdded = new Set(oemIDsAdded);
    if (event.target.checked === true) {
      setShowModalMO(true);
      if (!newOemIDsAdded.has(event.target.id)) {
        newOemIDsAdded.add(event.target.id);
      }
    } else {
      if (newOemIDsAdded.has(event.target.id)) {
        newOemIDsAdded.delete(event.target.id);
      }
    }
    setOemIDsAdded(newOemIDsAdded);
  };

  const handleReinstateOEMRegionButton = (e: any) => {
    e.preventDefault();
    setShowOEMRegionReinstateModal(true);
  }

  const handleConfirmRemoveOEMRegion = async () => {
    if (!isLoadingChangingOEMRegions) {
      setIsLoadingChangingOEMRegions(true);

      // Build the updated list of OEMs without the one removed
      let newOemIDs = currentShop?.oemIDs?.filter((oemID) => !oemIDsRemoved.has(oemID));

      let result = await updateOEMRegions(
        currentShop.shopID,
        regionCode,
        newOemIDs
      );
      if (result?.error) {
        toast.error(result.error, {
          containerId: 'standard',
        });
        setIsLoadingChangingOEMRegions(false);
      } else {
        toast.dismiss();
        let toastMessage = TextOnly('OEMRegionsUpdated');
        if (result.payment > 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: result.payment.toFixed(2)});
        } else if (result.payment < 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenCreditedAmount', {amount: (-1 * result.payment).toFixed(2)});
        }
        toast.success(toastMessage, {
          containerId: 'standard',
        });

        setIsLoadingChangingOEMRegions(false);
        setShowOEMRegionRemovalModal(false);
        setOemIDsRemoved(new Set());

        await fetchUser(currentShop.shopID);
      }
    }
  }

  const handleConfirmOEMRegionReinstate = async () => {
    if (!isLoadingChangingOEMRegions) {
      setIsLoadingChangingOEMRegions(true);

      const self = this;

      let result = await addReinstateOEMRegions(
        currentShop.shopID,
        regionCode,
        [...oemIDsAdded],
      );
      if (result?.error) {
        toast.error(result.error, {
          containerId: 'standard',
        });
        setIsLoadingChangingOEMRegions(false);
      } else {
        toast.dismiss();
        let toastMessage = TextOnly('OEMRegionsUpdated');
        if (result.payment > 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: result.payment.toFixed(2)});
        } else if (result.payment < 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenCreditedAmount', {amount: (-1 * result.payment).toFixed(2)});
        }
        toast.success(toastMessage, {
          containerId: 'standard',
        });

        setIsLoadingChangingOEMRegions(false);
        setShowOEMRegionReinstateModal(false);
        setOemIDsAdded(new Set());

        await fetchUser(currentShop.shopID);
      }
    }
  }

  const handleAddUsersButton = (e: any) => {
    e.preventDefault();

    const hasLastFour = !!currentShop!.shopPaymentInfo?.last4;

    if (!hasLastFour) {
      setAlertTitle(TextOnly('noPaymentMethodAvailable'));
      setAlertMessage(
        <p>
          <Text tid="noPaymentMethodForIncreasingUsers" />
        </p>
      );
      setShowModal(true);
    } else {
      setShowAddUsersModal(true);
    }
  }

  const handleNumToAddChange = (event: any) => {
    let newNumToAdd = event.target.value;
    if (newNumToAdd !== numToAdd) {
      setNumToAdd(newNumToAdd.toString());
    }
  };

  const handleCancelModalMO = () => {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowModalMO(false);
    }, 250);
  };

  const handleConfirmModal = async (e: any) => {
    e.preventDefault();

    setIsLoadingIncreaseMaxUsers(true);

    let newNumToAdd = parseInt(numToAdd, 10);
    let ret = await addUsersToShopCapacity(
      currentShop.shopID,
      newNumToAdd
    );
    if (ret?.error) {
      setShowAddUsersModal(false);
      setIsLoadingIncreaseMaxUsers(false);
      setAlertMessage(ret.error);
      setShowModal(true);
    } else {
      await fetchUser();

      const newNumAdded = numToAdd;

      document.querySelector('.c-modal')?.classList.add('closed');
      setTimeout(() => {
        setShowAddUsersModal(false);
        setIsLoadingIncreaseMaxUsers(false);
        setNumToAdd('');
      }, 350);

      toast.success(
        `${TextOnly('usersAddedToShop', {'num': newNumAdded})} ${TextOnly(
          'youHaveBeenCharged'
        )} $${ret.payment} ${TextOnly('today')}.`,
        { containerId: 'standard' }
      );
    }
  }

  const handleCancelModal = () => {
    const modalSlider = document.querySelector('.c-modal-slider');
    if (modalSlider) {
      modalSlider.classList.add('closed');
    }
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowOEMRegionRemovalModal(false);
      setOemIDsRemoved(new Set());
      setShowAddUsersModal(false);
      setShowOEMRegionReinstateModal(false);
      setIsLoadingIncreaseMaxUsers(false);
      setShowModal(false);
      setShowCancelSubscriptionModal(false);
    }, 350);
  }


  return (
    <div className={`${className ?? ''} l-flex-column`}>
      {!isSmallScreen &&
        <div className="h2 u-margin-bottom u-padding-left">AutoAuth</div>
      }
      <div className="c-box u-height-min-800 u-height-100-percent">
        <div className={`l-container u-max-width-560px`}>
          <section className="c-section">
            <label className="c-section__label">
              <Text tid="currentPlan" />
            </label>
            <div className="c-section__content">
              <span className="c-section__key u-font-weight-bold">
                AutoAuth {currentShop.shopType}
              </span>
            </div>
          </section>

          {/* MANUFACTURERS */}
          <div className="c-section u-padding-top-large">
            <div className={!isMediumScreen && !isSmallScreen ? "l-flex-row l-flex-gap-20" : ''}>
              <div className='u-margin-top'>
                <section className="c-section">
                  <label className="c-section__label">
                    <Text tid="region" />
                  </label>
                  <div className="c-section__content">
                    <span className="c-section__key">
                      {shopRegionName}
                    </span>
                  </div>
                </section>
              </div>
              <section className="c-section">
                <div className="c-field u-margin-top">
                  <CompatibleToolsModal
                    openButtonText={TextOnly('vehicleToolCompatibility')}
                    closeButtonText={TextOnly('close')}
                    showOnlyRegion={currentRegionName}
                    chartData={compatiblityChart}
                    whiteButton={true}
                    noCloseButton={true}
                  />
                </div>
              </section>
            </div>
            <div className={`${(isMediumScreen || isSmallScreen) && 'l-flex-column'} c-section__content l-flex-gap-20 u-padding-top-large`}>
              <section className="c-section u-width-100-percent">
                <label className="c-section__label">
                  <Text tid="activeOems" />
                </label>
                <div className="c-section__content">
                  <span className="c-section__key">
                    <ul className='u-padding-none u-margin-none'>
                      {oemByRegionList[regionCode] ?? ''}
                    </ul>
                  </span>
                </div>
              </section>
              { oemRegionsToAdd.length ?
                <form className="u-width-100-percent">
                  <section className="c-section u-margin-bottom-small">
                    <label className="c-section__label">
                      <Text tid="inactiveOEMs" />
                    </label>
                    <div className="c-section__content">
                      <span className="c-section__key u-margin-none">
                        {oemRegionsToAddComponent}
                      </span>
                    </div>
                    <div className="c-field u-margin-top-large">
                      <LoaderButton
                        id="changeOemRegions"
                        className="c-btn-full"
                        type="button"
                        isLoading={isLoadingChangingOEMRegions}
                        disabled={
                          oemIDsAdded.size === 0
                        }
                        text={TextOnly('addOems')}
                        loadingText={TextOnly('savingOemRegions')}
                        onClick={handleReinstateOEMRegionButton}
                      />
                    </div>
                  </section>

                </form>
              : '' }
            </div>
          </div>

          <div className={`${isMediumScreen || isSmallScreen ? 'l-flex-column' : 'l-flex-gap-20'} c-section__content`}>
            {/* TECH CERTS */}
            { systemConfig.CERTIFIED_TECH_STATE === "ACTIVE" ?
              <div className='l-flex-column u-width-100-percent'>
                <section className="c-section u-padding-top-large">
                  <label className="c-section__label">
                    <Text tid="technicianCertifications" />
                  </label>
                  <div className="c-section__content">
                    <span className="c-section__key text-bold">
                      {currentShop.shopMaxTechCerts ?? 0} {currentShop.shopMaxTechCerts
                        ? `(${currentShop.numUsedTechCerts || TextOnly('none')} ${TextOnly('used')})`
                        : ''
                      }
                    </span>
                  </div>
                </section>

                <div className="u-margin-top-large">
                  <AddTechCertification
                    perTechCertRate={perTechCertRate}
                    currentShop={currentShop}
                    fetchUser={fetchUser}
                  />
                </div>
              </div>
            : null }

            {/* MAX USERS/TOOLS */}
            <div className={systemConfig.CERTIFIED_TECH_STATE === "ACTIVE" ? 'u-width-100-percent' : 'u-width-50-percent'}>
              <section className="c-section u-padding-top-large">
                <label className="c-section__label">
                  <Text tid="maximumShopUsers" />
                </label>
                <div className="c-section__content">
                  <span className="c-section__key text-bold">
                    {user.shopMaxUsers}
                  </span>
                </div>
              </section>
              <div className="c-select background-white u-margin-top-large">
                <select
                  id="addUsers"
                  onChange={handleNumToAddChange}
                  value={numToAdd || 'default'}
                >
                  <option key="default" value="default" disabled>
                    {TextOnly("addShopUsersTools")}
                  </option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                      15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 80,
                      100, 150, 200, 300,
                      ].filter((num) => maxAddUsers >= num)
                      .map((num) => {
                            let rate = num * perUserRate;
                            return (
                              <option key={num} value={num.toString()}>
                                {num} (+${rate}/{TextOnly('year')})
                              </option>
                            );
                          })}
                </select>
              </div>
              <div className="c-field u-margin-top-large">
                <LoaderButton
                  id="increaseMaxUsers"
                  className="c-btn-full"
                  type="button"
                  isLoading={isLoadingIncreaseMaxUsers}
                  disabled={
                    numToAdd === null ||
                    numToAdd === '' ||
                    Number.isNaN(numToAdd)
                  }
                  text={TextOnly('upgradeUsersToolsCapacity')}
                  loadingText={TextOnly('increasingUsers')}
                  onClick={handleAddUsersButton}
                />
                {maxAddUsers <= 0 ?
                  currentShop.shopType === "STANDARD" ?
                  (
                    <p className="u-font-weight-bold">
                      <Text
                        tid="standardReachedMaxUsers"
                        sub={{
                          maxUserCount: (
                            <span>
                              {currentShop?.shopUserCap
                                ? currentShop?.shopUserCap
                                : isPlus
                                  ? config?.shopMaxValues.plusMaxUsers
                                  : config?.shopMaxValues.standardMaxUsers
                              }
                            </span>
                          ),
                          upgradePlus: (
                            <a
                              href="/upgradeToPlus/comparePlans"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {TextOnly('upgradeToStandardPlus')}
                            </a>
                          ),
                        }}
                      />
                    </p>
                  ) :
                  (
                    <p className="u-font-weight-bold">
                      <Text
                        tid="reachedMaxUsers"
                        sub={{
                          userCount: (
                            <span>
                              {user.shopMaxUsers}
                            </span>
                          ),
                          maxUserCount: (
                            <span>
                              {currentShop?.shopUserCap ? currentShop?.shopUserCap
                                : isPlus ? config?.shopMaxValues.plusMaxUsers
                                  : config?.shopMaxValues.standardMaxUsers
                              }
                            </span>
                          ),
                          contactLink: (
                            <a
                              href="https://info.autoauth.com/contact/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {TextOnly('contactUs')}
                            </a>
                          ),
                        }}
                      />
                    </p>
                  ) : null}
              </div>
            </div>
          </div>

          <section className="c-section">

            { currentShop!.shopSubscriptionState === PAYMENT_STATES.USER_CANCEL ?
              <SubEndingNotify
                paidThroughDate={subEndDate ?? ''}
                last4={currentShop?.shopPaymentInfo?.last4}
                expired={expired}
              />
              :
              <NextPayment
                currentShop={currentShop}
                user={user}
                subscriptionType={'AutoAuth'}
                config={config}
              />
            }
          </section>

          <div className="u-margin-top-large">
            <form className="l-container-sm">
              <div>
                {paymentState !== PAYMENT_STATES.TERMINATED &&
                paymentState !== PAYMENT_STATES.USER_CANCEL &&
                paymentState !== PAYMENT_STATES.NONE ?
                  <LoaderButton
                    type="button"
                    className="c-btn-outline background-white c-btn-full"
                    isLoading={isLoadingCancelSubscription}
                    text={TextOnly('cancelSubscription')}
                    loadingText={TextOnly('canceling')}
                    onClick={handleCancelSubscriptionButton}
                  />
                : (paymentState == PAYMENT_STATES.TERMINATED || paymentState === PAYMENT_STATES.USER_CANCEL) ?
                    // If not expired and last4 exists then let them reinstate
                    (!expired && currentShop?.shopPaymentInfo?.last4) ?
                      <div className='l-container-center u-margin-bottom-small'>
                        <LoaderButton
                          id="reinstateShop"
                          type="button"
                          className="c-btn-full"
                          isLoading={isLoadingReinstateShop}
                          text={TextOnly('reinstateShop')}
                          loadingText={TextOnly('reinstatingShop')}
                          onClick={handleReinstateShop}
                        />
                      </div>
                    :
                      <div className='l-container-center u-margin-bottom-small'>
                        <ToolTip
                          text={TextOnly('updatePaymentMethodToReinstate')}
                        >
                          <LoaderButton
                            id="reinstateShop"
                            type="button"
                            className="c-btn-full"
                            isLoading={isLoadingReinstateShop}
                            text={TextOnly('reinstateShop')}
                            loadingText={TextOnly('reinstatingShop')}
                            onClick={handleReinstateShop}
                            disabled={true}
                          />
                        </ToolTip>
                      </div>
                  : ''
              }
              </div>
              {currentShop?.shopType ===
                SHOP_TYPES.STANDARD && (
                <div className="u-margin-top-large">
                  <NavLink
                    className="c-btn-dark c-btn-full"
                    to="/upgradeToPlus/comparePlans"
                  >
                    <Text tid="upgradeToStandardPlus" />
                  </NavLink>
                </div>
              )}
              { (currentShop?.shopType === SHOP_TYPES.PLUS && !isDowngradingAtRenewal) ?
                <div className="u-margin-top-large">
                  <ToolTip
                    text={TextOnly('downgradeDisableExplain')}
                  >
                    <div>
                      <LoaderButton
                        type="button"
                        className="c-btn-outline background-white c-btn-full"
                        isLoading={isLoadingDowngradeSubscription}
                        disabled={!eligibleToDowngrade()}
                        text={TextOnly('downgradeToStandard')}
                        loadingText={TextOnly('downgrading')}
                        onClick={handleDowngradeSubscriptionButton}
                      />
                    </div>
                  </ToolTip>
                </div>
                : ''
              }
              { (currentShop?.shopType === SHOP_TYPES.PLUS && isDowngradingAtRenewal) ?
                <div className="u-margin-top-large">
                  <LoaderButton
                    type="button"
                    className="c-btn-full"
                    isLoading={isLoadingCancelDowngradeSubscription}
                    disabled={!eligibleToDowngrade()}
                    text={TextOnly('cancelDowngradeToStandard')}
                    loadingText={TextOnly('cancelingDowngrading')}
                    onClick={handleCancelDowngradeSubscriptionButton}
                  />
                </div>
                : ''
              }

            </form>
          </div>
        </div>
      </div>

      <Dialog
          isOpen={showModalMO}
          className="c-modal"
          aria-label={TextOnly('oemCompatibility')}
        >
          <h1 className="c-modal__heading">
            <Text tid="oemCompatibility" />
          </h1>

          <div className="c-modal__body">
            <CompatibleTools
              showOnlyRegion={currentRegionName}
              closeFunction={handleCancelModalMO}
              closeButtonText={TextOnly('confirm')}
              moveCloseButtonToTop={true}
              chartData={compatiblityChart}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={showAddUsersModal}
          onDismiss={ ()=> {if(!isLoadingIncreaseMaxUsers) handleCancelModal}}
          className="c-modal"
          aria-label={TextOnly('confirmAddUsers')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={handleCancelModal}
            disabled={isLoadingIncreaseMaxUsers}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmAddUsers" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToAdd" sub={{num: <>{numToAdd}</>}} />
            </p>
            <p>
              <Text tid="youWillBeCharged" /> $
              {parseInt(numToAdd) * perUserRate}/
              <Text tid="year" /> <Text tid="proratedPaymentExplain" />
            </p>
            <p className={perUserRate > config?.prices?.plusUserAddon ? "u-text-error" : 'hide-element'}>
              <Text tid="note" />
              <Text tid={"userUpchargeExplain"} sub={{base: userBasePrice, upcharge: oemUserUpcharge}} />
            </p>
            <p className={currentShop?.crmState === "ACTIVE" && crmUserUpcharge ? "u-text-error" : 'hide-element'}>
              <Text tid="note" />
              <Text tid={"crmSubscriptionWillChange"} sub={{upcharge: crmUserUpcharge}} />
            </p>
            <button
              className="c-btn-outline"
              onClick={handleCancelModal}
              disabled={isLoadingIncreaseMaxUsers}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={isLoadingIncreaseMaxUsers}
              text={TextOnly('confirm')}
              loadingText={TextOnly('savingChanges')}
              onClick={handleConfirmModal}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={showOEMRegionRemovalModal}
          onDismiss={handleCancelModal}
          className="c-modal"
          aria-label={TextOnly('confirmOEMRegions')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          { removeOEMAllowed ?
            <>
              <h1 className="c-modal__heading">
                <Text tid="confirmOEMRegions" />
              </h1>

              <div className="c-modal__body">
                <p>
                  <Text tid="areYouSureYouWantToRemoveOEMRegions" />
                </p>
                <span>
                  <ul>
                    { oemRegionModalList }
                  </ul>
                </span>
                <p>
                  <Text tid={"oemRegionExplanation"} sub={{ date: <>{shopPaymentInfo?.nextBillingDate}</> }}/>
                </p>
                <p>
                  <Text tid={"subscriptionPriceReductionOEMRegion"} sub={{amount: <>{removalPriceChange}</>}}/>
                </p>
                <p className={removalPriceChange > config?.prices?.oemRegionAddon * oemIDsRemoved.size
                    ? "u-text-error" : 'hide-element'}>
                  <Text tid="note" />
                  <Text tid={"oemUpchargeExplain"} sub={{base: oemBasePrice, upcharge: oemUserUpcharge}} />
                </p>
                <button
                  className="c-btn-outline"
                  onClick={handleCancelModal}
                  disabled={isLoadingChangingOEMRegions}
                >
                  <Text tid="cancel" />
                </button>{' '}
                <LoaderButton
                  type="button"
                  isLoading={isLoadingChangingOEMRegions}
                  text={TextOnly('confirm')}
                  loadingText={TextOnly('savingChanges')}
                  onClick={handleConfirmRemoveOEMRegion}
                />
              </div>
            </>
          :
            <>
              <h1 className="c-modal__heading">
                <Text tid="minimumNumberOfOEMs" />
              </h1>
              <div className="c-modal__body">
                <p>
                  <Text tid="cannotRemoveLastOEM" />
                </p>
              </div>
            </>
          }
        </Dialog>

        <Dialog
          isOpen={showOEMRegionReinstateModal}
          onDismiss={handleCancelModal}
          className="c-modal"
          aria-label={TextOnly('confirmReinstateOEMRegions')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={handleCancelModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmReinstateOEMRegions" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToReinstateOEMRegions" />
            </p>
            <span>
              <ul>
                { reinstateOemRegionModalList }
              </ul>
            </span>
            <p>
              <Text tid={"oemRegionReinstatedExplanation"} />
            </p>
            <p>
              { OEMAddPriceChangeStatement }
            </p>
            <p className={chargeToday && chargeToday > (config?.prices?.oemRegionAddon * reinstateOemRegionModalList.length)
              ? "u-text-error"
              : 'hide-element'}>
              <Text tid={renewalTier === 'AutoAuth STANDARD'
                ? "noteForPlus"
                : "note"}/>
              <Text
                tid="oemUpchargeExplain"
                sub={{base: oemBasePrice, upcharge: oemUserUpcharge}}/>
            </p>
            <button
              className="c-btn-outline"
              onClick={handleCancelModal}
              disabled={isLoadingChangingOEMRegions}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={isLoadingChangingOEMRegions}
              text={TextOnly('confirm')}
              loadingText={TextOnly('savingChanges')}
              onClick={handleConfirmOEMRegionReinstate}
            />
          </div>
        </Dialog>

        <AlertModal
          title={alertTitle}
          message={<>{alertMessage}</>}
          showModal={showModal}
          size="small"
          handleCancel={handleCancelModal}
        />

        <ConfirmModal
          showConfirmModal={showCancelSubscriptionModal}
          closeModal={handleCancelModal}
          onConfirm={handleConfirmCancelSubscriptionModal}
          textModal={
            <>
              <p className='text-bold'>{TextOnly('confirmCancelSubscriptionMessage')}</p>
              <p className='text-bold'>{currentShop?.crmState === "ACTIVE" ? TextOnly('confirmCancelShopWithCRM') : ''}</p>
            </>
          }
          className="c-modal-slider"
          titleModal={TextOnly('confirmCancelSubscription')}
          textCancelButton={TextOnly('dismiss')}
          textLoadingConfirmButton={TextOnly('canceling')}
        />

        <ConfirmModal
          showConfirmModal={showDowngradeSubscriptionModal}
          closeModal={handleCancelDowngradeSubscription}
          onConfirm={handleConfirmDowngradeSubscriptionModal}
          textModal={
            <>
              <p className='text-bold'><Text tid="confirmDowngradeSubscriptionMessage" /></p>
              <p className='text-bold'>{currentShop?.crmState === "ACTIVE" ? TextOnly('confirmCancelShopWithCRM') : ''}</p>
            </>
          }
          className="c-modal-slider"
          titleModal={TextOnly("confirmDowngradeSubscription")}
          textCancelButton={TextOnly('dismiss')}
          textLoadingConfirmButton={TextOnly('downgrading')}
        />
    </div>

  );
}

export default AASubscription;