import React from 'react';
import { TextOnly } from '../components/Text';
import { convertOrderNumToDisplay, formatDateTime } from '../libs/utils';
import { useScreenSize } from '../context/ScreenSize';
import { capitalizeFirstLetter } from '../libs/utils-ts';

interface genericObj {
  [key: string]: any;
}

interface OrdersSubComponentProps {
  row: any;
  shop: genericObj;
  className?: string;
}
interface VehiclesSubComponentProps {
  row: any;
  shop: genericObj;
  className?: string;
  hiddenColumns?: any[];
}
interface LogSubComponentProps {
  oemRegions?: [];
  shop: genericObj;
  row: any;
  currentUser: genericObj;
  className?: string;
}

export const VehiclesSubComponent: React.FC<VehiclesSubComponentProps> = (props) => {
  const vehicle: genericObj = props.row.original;
  const hiddenColumns: any[] = props.hiddenColumns ?? [];
  const { windowWidth } = useScreenSize();

  // Matching vehicle entry fields with respective phrase text
  const detailFieldsText: genericObj = {
    // field name in row: text ID
    'vin': 'vin',
    'odometer': 'odometer',
  }

  hiddenColumns?.forEach((col: any) => detailFieldsText[col.accessor] = col.Header);

  const detailsObj: genericObj = {};

  for (const field in vehicle) {
    if (detailFieldsText.hasOwnProperty(field)) {
      detailsObj[TextOnly(detailFieldsText[field])] = vehicle[field];
    }
  };

  const detailsCard = cardBodyBuilder(detailsObj, null);

  return (
    <div className='u-margin-bottom'>
      <div className="c-card">
        <div className="h3 c-card__title u-margin-none">{TextOnly('details')}</div>
        <div className={`display-flex ${(windowWidth < 500) ? 'justifycontent-center' : ''}`}>
          <div className={`grid-container`}>
            {detailsCard}
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrdersSubComponent: React.FC<OrdersSubComponentProps> = (props) => {
  const order: genericObj = props.row.original;

  // Matching log entry fields with respective phrase text
  const detailFieldsText: genericObj = {
    // field name in log: text ID
    'description': 'description',
  }

  const detailsObj: genericObj = {};

  for (const field in order) {
    if (detailFieldsText.hasOwnProperty(field)) {
      detailsObj[TextOnly(detailFieldsText[field])] = order[field];
    }
  };

  const detailsCard = cardBodyBuilder(detailsObj, null);

  return (
    <div className='u-margin-bottom'>
      <div className="c-card">
        <h3 className="c-card__title u-margin-none">{TextOnly('details')}</h3>
        <div className={`grid-container`}>
          {detailsCard}
        </div>
      </div>
    </div>
  )
}

export const LogSubComponent: React.FC<LogSubComponentProps> = (props) => {
  const {
    actionCode,
    oemIDs,
    shopChanges,
    userChanges,
    targetUserID,
    addUserID,
    removedOemIDs,
    reinstatedOems,
    addedOems,
    removeFirstName,
    removeLastName,
    removeUserName,
    changes,
    customer,
    vehicle,
    order,
    event,
    technician,
  } = props.row.original;

  let isUpdate = false;
  const { windowWidth } = useScreenSize();

  const logEntry: genericObj = props.row.original;
  const oemRegions: [] = props?.oemRegions || [];
  const shop: genericObj = props?.shop || {};
  const currentUser: genericObj = props?.currentUser;

  const getOemNames = (oemIDs?: string[]) => {
    return oemIDs?.map((oemID: string) => {
      const oemRegion: genericObj = oemRegions.find((oem: genericObj) => oem.oemID === oemID) || {};
      return oemRegion?.oemName;
    });
  }

  const oemNames: any[] | undefined = getOemNames(oemIDs);
  const removedOemNames: any[] | undefined = getOemNames(removedOemIDs);
  const reinstatedOemNames: any[] | undefined = getOemNames(reinstatedOems);
  const addedOemNames: any[] | undefined = getOemNames(addedOems);

  // Matching log entry fields with respective phrase text
  const detailFieldsText: genericObj = {
    // field name in log: text ID
    'numToAdd': 'numberAdded',
    'response': 'response',
    'shopUserState': 'userState',
    'shopUserRole': 'userRole',
    'toolName': 'toolNameEdited',
    'toolNotes': 'toolNotesEdited',
    'toolManufacturer': 'toolManufacturer',
    'toolModel': 'toolModel',
    'toolSerial': 'toolSerial',
    'statusChange': 'shopState',
    'oemIDs': 'oems',
    'removedOemIDs': 'removedOems',
    'reinstatedOems': 'reinstatedManufacturers',
    'addedOems': 'addedOems',
  }

  const logObj: genericObj = {};

  switch (actionCode) {
    case 'UPDATE_SHOP_INFO':
      const shopChangesText: genericObj = {
        // field name in log: text ID
        'shopName': 'shopName',
        'shopType': 'shopType',
        'streetAddress1': 'streetAddress1',
        'streetAddress2': 'streetAddress2',
        'city': 'city',
        'state': 'state',
        'zip': 'zipCode',
        'country': 'country',
        'phone': 'phoneNumber'
      };
      isUpdate = true;
      shopChanges?.forEach((change: any) => {
        const { field, oldVal, newVal } = change;
        const changeField = TextOnly(shopChangesText[field]);
        logObj[changeField] = { oldVal, newVal };
      });
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          const value = (field === 'oemIDs')
            ? oemNames?.join(', ')
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
      break;

    case 'UPDATE_USER_INFO':
      const userChangesText: genericObj = {
        'firstName': 'firstName',
        'lastName': 'lastName',
        'email': 'email',
        'language': 'language',
        'userState': 'userState',
        'phoneNumber': 'phoneNumber',
      };
      isUpdate = true;
      userChanges?.forEach((change: any) => {
        const { field, oldVal, newVal } = change;
        const changeField = TextOnly(userChangesText[field]);
        logObj[changeField] = { oldVal, newVal };
      });
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          logObj[TextOnly(detailFieldsText[field])] = logEntry[field];
        }
      };
      break;

    case 'UPDATE_SHOP_MAX_TECH_CERTS':
      const addedTechCerts = logEntry.numToAdd;
      logObj[TextOnly('addedTechCerts')] = addedTechCerts;
      break;

    case 'UPDATE_SHOP_MAX_USERS':
      const usersAdded = logEntry.numToAdd;
      logObj[TextOnly('addedUsers')] = usersAdded;
      break;

    case 'SET_USER_STATE_AT_SHOP':
      const targetUser = currentUser.userID === targetUserID
        ? currentUser
        : shop.shopUsers.find((user: any) => user.userID === targetUserID);
      logObj[TextOnly('editedUser')] = targetUser?.userName || `<${TextOnly('removedUser')}>`;
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          logObj[TextOnly(detailFieldsText[field])] = logEntry[field];
        }
      };
      break;

    case 'ADD_USER_TO_SHOP':
      const addedUser = currentUser.userID === addUserID
        ? currentUser
        : shop.shopUsers.find((user: any) => user.userID === addUserID);
      logObj[TextOnly('userAdded')] = addedUser?.userName || `<${TextOnly('removedUser')}>`;
      break;

    case 'CHANGE_USER_SHOP_ROLE':
      isUpdate = true;
      const updatedRoleUser = shop.shopUsers.find((user: any) => user.userID === props.row.original.targetUserID);
      logObj[TextOnly('editedUser')] = updatedRoleUser?.userName || `<${TextOnly('removedUser')}>`;
      changes?.forEach((change: any) => {
        const { field, oldVal, newVal } = change;
        if (!field.includes('ID') && field !== 'updatedOn') {
          logObj[capitalizeFirstLetter(TextOnly(field))] = {oldVal, newVal}
        }
      });
      break;

    case 'REINSTATE_SHOP_OEM_REGIONS':
      if (reinstatedOems?.length || addedOems?.length) {
        if (reinstatedOems.length) logObj[TextOnly('reinstatedManufacturers')] = reinstatedOemNames?.join(', ');
        if (addedOems.length) logObj[TextOnly('addedOems')] = addedOemNames?.join(', ');
      } else {
        logObj[TextOnly('reinstatedOems')] = oemNames?.join(', ');
      }
      break;

    case 'REMOVE_SHOP_OEM_REGIONS':
      logObj[TextOnly('removedOems')] = removedOemNames?.join(', ');
      break;

    case 'REGISTER_TOOL_FOR_SHOP':
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          let value = (field === 'toolName') ? logEntry[TextOnly('toolName')]
            : (field === 'toolNotes') ? logEntry[TextOnly('toolNotes')]
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
      break;

    case 'REMOVE_USER_FROM_SHOP':
      logObj[TextOnly('removedUser')] = `${removeFirstName} ${removeLastName}`;
      logObj[TextOnly('username')] = removeUserName;
      break;

    case 'CRM_CREATE_CUSTOMER':
      logObj[TextOnly('customerName')] = `${customer?.firstName} ${customer?.lastName}`;
      logObj[TextOnly('email')] = customer?.email;
      logObj[TextOnly('phoneNumber')] = customer?.phoneNumber;
      break;

    case 'CRM_UPDATE_CUSTOMER':
    case 'CRM_UPDATE_VEHICLE':
    case 'CRM_UPDATE_ORDER':
    case 'CRM_UPDATE_EVENT':
      isUpdate = true;
      changes?.forEach((change: any) => {
        const { field, oldVal, newVal } = change;
        if (!field.includes('ID') && field !== 'updatedOn') {
          if (field === 'status') {
            logObj[TextOnly(field)] = {
              oldVal: TextOnly(oldVal),
              newVal: TextOnly(newVal),
            }
          } else if (field === 'allDay') {
            logObj[TextOnly(field)] = {
              oldVal: oldVal ? 'True' : 'False',
              newVal: newVal ? 'True' : 'False'
            }
          } else {
            logObj[capitalizeFirstLetter(TextOnly(field))] = {oldVal, newVal}
          }
        }
      });
      break;

    case 'CRM_CREATE_VEHICLE':
      logObj[TextOnly('vehicle')] = `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`;
      if (vehicle?.vin) logObj[TextOnly('vin')] = vehicle.vin;
      if (vehicle?.color) logObj[TextOnly('color')] = vehicle.color;
      if (vehicle?.odometer) logObj[TextOnly('odometer')] = vehicle.odometer;
      if (vehicle?.licensePlate) logObj[TextOnly('licensePlate')] = vehicle.licensePlate;
      logObj[TextOnly('customerName')] = `${customer?.firstName} ${customer?.lastName}`;
      logObj[TextOnly('email')] = customer?.email;
      break;

    case 'CRM_REMOVE_VEHICLE':
      logObj[TextOnly('customerName')] = `${customer?.firstName} ${customer?.lastName}`;
      logObj[TextOnly('vehicle')] = `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`;
      break;

    case 'CRM_CREATE_ORDER':
      logObj[TextOnly('order') + ' #'] = convertOrderNumToDisplay(order?.orderNumber);
      logObj[TextOnly('orderTitle')] = order?.title;
      if (order?.description) logObj[TextOnly('description')] = order?.description;
      logObj[TextOnly('customerName')] = `${customer?.firstName} ${customer?.lastName}`;
      logObj[TextOnly('vehicle')] = `${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`;
      break;

    case 'CRM_CREATE_EVENT':
      logObj[TextOnly('title')] = event?.title;
      const eventStartDate = new Date(new Date(event?.startDate).toLocaleString('en-US', { timeZone: event?.timeZone }));
      logObj[TextOnly('date')] = eventStartDate.toLocaleString()
      if (event?.description) logObj[TextOnly('description')] = event?.description;
      if (technician) logObj[TextOnly('technician')] = `${technician?.firstName} ${technician?.lastName} (${technician?.userName})`;
      if (order) {
        logObj[TextOnly('order') + ' #'] = convertOrderNumToDisplay(order?.orderNumber);
        logObj[TextOnly('orderTitle')] = order?.title;
      }
      break;

    default:
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          let value = (field === 'oemIDs')
            ? oemNames?.join(', ')
            // checking length to find if tool name = "" or tool notes = []
            : ((field === 'toolName' || field === 'toolNotes') && !logEntry[field].length)
            ? `<${TextOnly('removed')}>`
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
  }

  const logCard = cardBodyBuilder(logObj, null, isUpdate);

  return (
    <div className='u-margin-bottom'>
      <div className="c-card">
        <div className="h3 c-card__title u-margin-none">{TextOnly('details')}</div>
        <div className={`display-flex ${(windowWidth < 500) ? 'justifycontent-center' : ''}`}>
          <div className={`grid-container${isUpdate ? '__changes' : ''}`}>
            {logCard}
          </div>
        </div>
      </div>
    </div>
  )
}

const cardBodyBuilder = (cardObj: genericObj, styles: any, isUpdate?: boolean) => {
  const { windowWidth } = useScreenSize();

  if (
    Object.keys(cardObj).every((attr: any) => {
      return cardObj[attr] === undefined;
    })
  ) {
    return null;
  }

  return Object.keys(cardObj).map((attr, idx) => {
    let attrValue = cardObj[attr];
    if (isUpdate && typeof attrValue === 'object') {
      return (
        <React.Fragment key={idx}>
          <div className={`grid-th white-space-nowrap`}>{attr}: </div>
          <div className={`grid-th-oldval`}>
            <span>{attrValue.oldVal ? attrValue.oldVal : `<${TextOnly('none')}>`}</span>
          </div>
          <div className={`grid-th-arrow`}><i className="fa-solid fa-right" /></div>
          <div className={`grid-th-newval`}>
            <span>{attrValue.newVal ? attrValue.newVal : `<${TextOnly('none')}>`}</span>
          </div>
        </React.Fragment>
      )
    } else if (isUpdate && attrValue) {
      return (
        <React.Fragment key={idx}>
          <div className={`grid-th white-space-nowrap`}>{attr}: </div>
          <div className={`grid-th-oldval`}></div>
          <div className={`grid-th-arrow flex-center`}>
            <span>{attrValue}</span>
          </div>
          <div className={`grid-th-newval`}></div>
        </React.Fragment>
      )
    } else if (attrValue) {
      const dateObj = attr === TextOnly('createdOn') ? formatDateTime(attrValue, {windowWidth: windowWidth}) : null;
      return (
        <React.Fragment key={idx}>
          <div className={`grid-th ${styles ? styles[attr] : ''}`}>{attr}: </div>
          <div className={`${styles ? styles[attr] : ''}`}>
            {attrValue instanceof Array && attrValue.length > 1 ? (
              <ul>
                {attrValue?.map((value: any, index: number) => (
                  <li key={index}>
                    <span>{value}</span>
                  </li>
                ))}
              </ul>
            ) : attrValue === `<${TextOnly('removedUser')}>`
                || attrValue === `<${TextOnly('removed')}>` ? (
              <div className="u-text-warning">
                <span>{attrValue}</span>
              </div>
            ) : (
              <span>{dateObj ?? attrValue}</span>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  });
};