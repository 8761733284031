export const en = {
  // Info page
  newFeatures: 'New Features',
  login: 'Login',
  loggingIn: 'Logging in…',
  aboutUs: 'About Us',
  contactUs: 'Contact Us',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  refundCancelPolicy: 'Refund/Cancelation Policy',
  w9Form: 'W9 Form',
  registerNow: 'REGISTER NOW',
  tagLine:
    'Providing an Independent Trust Authority for OEMs, Tools Vendors & Service Shops to securely service modern vehicles',

  // Signup page
  validationSentTo: 'Account Validation link has been sent to',
  loginInstructions:
    'After validating your account you can log in by clicking the Login button on the top right of the page',
  userSignup: 'User Signup',
  username: 'Username',
  usernameHint:
    'Username must be at least 8 alphanumeric lowercase characters starting with a letter',
  firstName: 'First Name',
  firstNameHint:
    'First name cannot be blank and cannot contain special characters',
  lastName: 'Last Name',
  lastNameHint:
    'Last name cannot be blank and cannot contain special characters',
  email: 'Email',
  emailHint: 'Enter a valid email address',
  confirmEmail: 'Confirm Email',
  confirmEmailHint: 'Email and Confirm Email do not match',
  passwordHint:
    'Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter',
  confirmPassword: 'Confirm Password',
  confirmPasswordHint: 'Password and Confirm Password do not match',
  inviteCode: 'Invite Code',
  inviteCodeHint: 'Invite Code cannot be blank',
  needInviteCode: 'Need Invite Code?',
  termsConditions: 'Terms & Conditions',
  acceptTermsConditions: 'Accept Terms & Conditions',
  numberBraintree: 'Card Number',
  expirationDateBraintree: 'Expiration Date',
  cvvBraintree: 'CVV',
  postalCodeBraintree: 'Postal Code',
  signup: 'Signup',
  signingUp: 'Signing up...',
  pleaseProveHuman: 'Please prove you are human!',

  // Shop signup page
  userNotActiveMsg:
    'You must verify your email to activate your account either by clicking on the email sent to you or logging in and having a new email sent.',
  serviceCenterIndependentTechSignup:
    'Service Center or Independent Technician Signup',
  serviceCenterName: 'Service Center Name',
  shopNameHint:
    'Shop name cannot be blank and cannot contain special characters',
  primaryContact: 'Primary Contact',
  enterAutoAuthUserCredentials: 'Enter AutoAuth User Credentials',
  enterValidUsernameEmailAddress: 'Enter a valid username or email address',
  passwordDoesNotConform: 'Password does not conform to the password rules',
  enterPaymentMethod: 'Enter Payment Method',
  locale: 'en_US',
  hl: 'en',
  shareInfo: 'Share my Information for Marketing, Promotions and Offers from AutoAuth affiliates via email, social media and other channels',

  // Login page
  usernameOrEmail: 'Username or Email Address',
  password: 'Password',
  forgotPassword: 'Forgot Password?',
  resendConfirmLink: 'Resend Confirm Link',

  // Navbar
  home: 'Home',
  userProfile: 'User Profile',
  manageTools: 'Manage Tools',
  manageUsers: 'Manage Users',
  reports: 'Reports',
  logout: 'Logout',
  faq: 'FAQ',
  systemInfo: 'System Information',
  support: 'Support',

  // Home page
  welcome: 'Welcome to AutoAuth!',
  yourShops: 'Your Shops Affiliations',
  name: 'Name',
  shopState: 'Shop Status',
  myRole: 'My Role',
  myState: 'My Status',
  page: 'Page',
  of: 'of',
  rows: 'rows',
  previousPage: 'Previous Page',
  nextPage: 'Next Page',
  withdrawalFromShop: 'Withdrawal from Shop',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  USER: 'USER',
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  youHaveBeenInvited: 'You have been invited to become a USER for',
  NOTICE: 'NOTICE',
  PENDING: 'PENDING',
  toAccept: 'To accept',
  toReject: 'To reject',

  // User Profile Page
  profile: 'Profile',
  shopInfo: 'Shop Info',
  paymentHistory: 'Payment History',
  submittedForSettlement: 'Submitted for Settlement',
  settled: 'Settled',
  failedCharge: 'Failed Charge',
  errorSendingVerifyCode: 'Error sending Verification Code',
  emailAddressVerified: 'Email Address has been verified',
  invalidVerifyCode: 'Invalid Verification Code',
  profileUpdated: 'Profile Updated',
  paymentMethodRemoved: 'Payment Method Removed',
  paymentMethodUpdated: 'Payment Method Updated',
  passwordUpdated: 'Password Updated',
  oldPasswordNotCorrect: 'Old password is not correct',
  shopUpdated: 'Shop Updated',
  userCapacityUpgrade: 'User Capacity Upgrade (prorated)',
  yearlySubscriptionRenewal: 'Yearly Subscription Renewal',
  initialSubscriptionCharge: 'Initial Yearly Subscription Charge',
  viewReceipt: 'View Receipt',
  shopAccountSuspended:
    'Shop Account has been SUSPENDED.  Please update payment information to resume unlocking and servicing vehicles.',
  shopAccountPastDue:
    'Shop Account is PAST DUE.  Please update payment information to ensure no disruption in service.',
  emailAddress: 'Email Address',
  validateEmail: 'Validate Email',
  emailSentMsg:
    'An email has been sent to your new email address. Copy the code found the email into the field below.',
  confirmationCode: 'Confirmation Code',
  submitConfirmationCode: 'Submit Confirmation Code',
  update: 'Update',
  updating: 'Updating…',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  verifyNewPassword: 'Verify New Password',
  changePassword: 'Change Password',
  changing: 'Changing…',
  shop: 'Shop',
  shopName: 'Shop Name',
  changeShopName: 'Change Shop Name',
  savingName: 'Saving name…',
  maximumShopUsers: 'Maximum Shop Users',
  year: 'year',
  increaseMaxUsers: 'Increase Max Users',
  increasingUsers: 'Increasing Users…',
  on: 'on',
  nextPayment: 'Next Payment',
  savedPaymentInfo: 'Saved Payment Info',
  paidThru: 'Paid Thru',
  exp: 'Exp',
  removePayment: 'Remove Payment',
  removingPayment: 'Removing Payment…',
  noPaymentMethodAvailable: 'No Payment Method Available.',
  yourSubscriptionEnded: 'Your Subscription ended on',
  yourSubscriptionWillEnd: 'Your Subscription will end on',
  savingPayment: 'Saving Payment…',
  history: 'History',
  date: 'Date',
  amount: 'Amount',
  chargeDescription: 'Charge Description',
  receipt: 'Receipt',
  confirmAddUsers: 'Confirm Add User(s) to Shop Capacity',
  areYouSureYouWantToAdd: 'Are you sure you want to add <num> user(s) to the Shop Capacity?',
  youWillBeCharged: 'You will be charged',
  proratedPaymentExplain:
    'prorated for the amount of time left in your subscription. Then the full amount each additional YEAR the subscription is active.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  usersAddedToShop: '<num> User(s) Added to Shop Capacity',
  youHaveBeenCharged: 'You have been charged',
  today: 'today',
  Today: 'Today',
  dismiss: 'Dismiss',
  autoAuthReceipt: 'AutoAuth Receipt',
  description: 'Description',
  transactionDate: 'Transaction Date',
  taxAmount: 'Tax Amount',
  taxExempt: 'Tax Exempt',
  yes: 'yes',
  no: 'no',
  orderId: 'Order ID',
  status: 'Status',
  paymentType: 'Payment Type',
  creditCardLast4: 'Credit Card Last 4',
  customerInfo: 'Customer Information',
  company: 'Company',
  owner: 'Owner',
  print: 'Print',

  // Manage Tools Page
  unknown: 'Unknown',
  toolOwnedByYourShop: 'This tool is already owned by your shop',
  toolAdded: 'Tool Added',
  toolsAdded: 'Tools Added',
  toolsRemoved: 'Tools Removed',
  toolAssignedToAnotherShop: 'Tool is already assigned to another shop',
  toolsFailed: 'The following tools failed',
  manufacturer: 'Manufacturer',
  model: 'Model',
  editToolName: 'Edit Tool Name',
  notes: 'Notes',
  addNote: 'Add Note',
  saveNotesMsg: "Do you want to save your notes before closing this window? You will lose any unsaved notes.",
  serialNo: 'Serial No.',
  addedOn: 'Added On',
  addedBy: 'Added By',
  actions: 'Actions',
  actionCode: 'Action Code',
  viewToolActions: 'View Tool Actions',
  vin: 'VIN',
  performedBy: 'Performed By',
  uploadFormat:
    'Drag File or Click and Browse to your File. File must be text file containing a single Serial Number on each line.',
  shopTools: 'Shop Tools',
  filter: 'Filter',
  noToolsThisShop: 'No Tools Associated with Shop',
  toolActions: 'Tool Actions',
  actionsPerformedBy: 'Actions performed by',
  from: 'from',
  addTool: 'Add Tool',
  pickToolType: 'Pick the Type of Tool',
  optional: 'Optional',
  serialNumber: 'Serial Number',
  serialNumberHint:
    "Serial Number cannot be blank and can only contain alphanumeric plus '-_/.' characters",
  add: 'Add',
  addingTool: 'Adding tool…',
  addingTools: 'Adding tools…',
  removingTools: 'Removing tools…',
  confirmToolFromShop: 'Confirm Remove Tool from Shop',
  areYouSureYouRemoveTool: 'Are you sure you want to remove this tool?',
  noneSelected: 'None Selected',
  toolModel: 'Tool Model',
  uploadFileOfSerialNo: 'Upload File of Serial Numbers',
  refresh: 'Refresh',
  refSuccess: 'Tool List Refreshed',

  // Manage Users Page
  couldntFindUsername: "Couldn't find user with username",
  userAssociatedWithShop: 'This user is already associated with this shop',
  usersWereInvited: '<num> user(s) were invited.',
  userInviteFailed: '<num> user(s) invite failed',
  clickDownloadFailed: 'Click to download list of failed',
  role: 'Role',
  state: 'State',
  changeRoleToAdmin: 'Change Role to Admin',
  changeRoleToUser: 'Change Role to User',
  changeToAdmin: 'Change to Admin',
  changeToUser: 'Change to User',
  removeFromShop: 'Remove from Shop',
  suspendUser: 'Suspend User',
  reactivateUser: 'Reactivate User',
  viewUserActions: 'View User Actions',
  removeFromList: 'Remove from List',
  usersOfMaxShopUsers: '<num> Users of <max> Maximum Shop Users',
  userBulkUploadFormat:
    "Drag File or Click and Browse to your File. File must be csv file containing a 'firstName', 'lastName', 'userRole' and 'email' columns.",
  dateAdded: 'Date Added',
  userActions: 'User Actions',
  addUser: 'Add User',
  enterUsernameToAdd: 'Enter Username of User to Add',
  maximumShopUsersReached: 'Maximum Shop Users Reached',
  searchForUser: 'Search for User',
  searchingUser: 'Searching User…',
  addUserCapacityHere: 'Add User Capacity Here',
  sendRequestToUser: 'Send Request to User',
  sendingRequest: 'Sending Request…',
  bulkImport: 'Bulk Import',
  uploadFileUsersToInvite: 'Upload File of Users to Invite to Join',
  downloadTemplateCSVFile: 'Download Template CSV file',
  downloadLastBulkInviteErrors: 'Download Last Bulk Invite Errors',
  clickToDownload: 'Click to download',
  noUsersAssociated: 'No Users Associated with Shop',
  addingUsers: 'Adding Users…',
  confirmRemoveUserShop: 'Confirm Remove User from Shop',
  areYouSureRemove: 'Are you sure you want to remove this user?',

  // Error Messages
  invalidUserRole: 'Invalid user role',
  missingParams: 'Missing parameters!',
  missingShopInfoParams: 'Missing shop info parameters!',
  missingUserInfoParams: 'Missing user info parameters!',
  ownerCannotRemoveSelfFromShop:
    'Shop owner can not remove themselves from a shop',
  onlyOwnerAdminCanRemoveUser:
    'Only a OWNER or ADMIN can remove another user from a shop',
  invalidResponse: 'Invalid response',
  userCannotModifyRoleState:
    'User can not modify their role and state at a shop',
  invalidShopUserState: 'Invalid shop user state',
  invalidShopUserRole: 'Invalid shop user role',
  userAccountNotActive: 'User account is not ACTIVE',
  shopNotActive: 'The shop is not ACTIVE',
  userNotAffiliatedWithShop: 'The user is not affiliated with this shop',
  couldNotGetShopUserCounts:
    'Could not retrieve shop user counts for this shop!',
  couldNotGetShopToolCount: 'Could not retrieve shop tool count for this shop!',
  usernameNotFound: 'Username not found!',
  unexpectedUserInfoError: 'Unexpected userInfo error',
  cannotUpdateAnotherUserInfo: 'Can not update another users information!',
  cannotUpdateUserNotActive: 'Can not update a user that is not ACTIVE!',
  emailAlreadyInUse: 'The provided email address is already in use!',
  invalidUserState: 'Invalid user state!',
  invalidToolModelForManufacturer: 'Invalid tool model for this manufacturer!',
  invalidToolManufacturer: 'Invalid tool manufacturer!',
  requestRefused: 'Request Refused',
  usernameAlreadyInUse: 'The provided username is already in use!',
  userEmailInvalid: 'User email has an invalid format!',
  invalidParams: 'Wrong format for one or more Parameters',

  paymentRefund: 'Payment Refund',
  shopUsers: 'Shop Users',
  shopSuspendedMessage:
    'Shop Account has been SUSPENDED or CANCELED.  Please update payment information to resume unlocking and servicing vehicles.',
  nextPaymentOf: 'Next Payment of',
  toBeChargedOn: 'to be charged on',
  reinstateShop: 'Reinstate Shop',
  link: 'Link',
  cancelSubscription: 'Cancel Subscription',
  transactionInfo: 'Transaction Information',
  browser: 'Browser',
  hostOS: 'Host OS',
  uploadSerialNoFileInstructions:
    'Drag File or Click and Browse to your File. File must be text file containing a single Serial Number on each line.',
  manageSignupLinks: 'Manage Signup Links',
  edit: 'Edit',
  remove: 'Remove',
  signupLinks: 'Signup Links',
  addSignupLink: 'Add Signup Link',
  editSignupLink: 'Edit Signup Link',
  validityCriteria: 'Validity Criteria',
  additionalFieldsToCollect: 'Additional Fields to Collect',
  noSignupLinks: 'No Signup Links',
  linkName: 'Link Name',
  standard: 'STANDARD',
  time: 'TIME',
  addCustomField: 'Add Custom Field',
  fieldName: 'Field Name',
  customFieldsFromUsers: 'Custom Fields to Collect from Users',
  fieldNameCannotBeBlank: 'Field Name cannot be blank',
  removeCustomField: 'Remove Custom Field',
  startEndTime: 'Start/End Time',
  yesterday: 'Yesterday',
  last30Days: 'Last 30 Days',
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  shopActions: 'Shop Actions',
  search: 'Search',
  searching: 'Searching...',
  pickUser: 'Pick User',
  pickTool: 'Pick Tool',

  noShopActions: 'No Shop Actions',
  noUserActions: 'No User Actions',
  noToolActions: 'No Tool Actions',
  additionalFields: 'Additional Fields',
  language: 'Language',
  selectLanguage: 'Select a language…',
  incorrectUserAndPass: 'Incorrect username or password',

  confirmCancelSubscription: 'Confirm Cancel AutoAuth Subscription',
  confirmCancelSubscriptionMessage:
    'Are you sure you want to cancel your AutoAuth subscription? Doing so you will still be able to securely unlock vehicles and perform Authenticated Diagnostics until your current year subscription expires. However, after that date you will no longer be able to do so.',
  confirmRemoveUserSignupLinkFromShop:
    'Confirm Remove User Signup Link From Shop',
  confirmRemovalFromShop: 'Confirm Removal From Shop',
  areYouSureYouWantToRemoveYourselfFrom:
    'Are you sure you want to remove yourself from',
  problemAuthenticating: 'There was a problem authenticating',
  clickHere: 'Click Here',
  toRegisterOwnShop: 'to register your own shop',
  yourUserStateIs: 'Your user state is',
  mustValidateEmailMessage:
    'You must validate the email address <email> before using this portal or change your email address in the User Profile tab.',
  emailValidationSentTo: 'Email Address Validation code has been sent to:',
  enterCodeToConfirmEmail: 'Enter code to confirm email.',
  submitConfirmCode: 'Submit Confirmation Code',
  shopDoesNotHaveFormOfPayment: 'Your shop does not have a form of payment.',
  mustAddPaymentShopExpired:
    'You must add a payment method to ensure no disruption of service. Your shop subscription has expired on <date>',
  mustAddPaymentShopWillExpire:
    'You must add a payment method to ensure no disruption of service. Your shop subscription will expire on <date>',
  addPaymentMethodHere: '(Add Payment Method Here)',
  click: 'Click',
  toRegisterTools:
    'to register tools for your shop so you can start servicing vehicles.',
  userStatePending: 'Your user state is Pending.',
  affiliateWithShopToUseToolsMessage:
    'Now that you have become an AutoAuth user your next step is to become affiliated with a Service Center Account to start using AutoAuth Certified Diagnostic tools.',
  serviceCenterSignupMessage:
    'If you are a Service Center owner/manager or you are an Independent Technician with your own AutoAuth Certified Diagnostic tools, click the link below to register yourself or your Service Center.',
  serviceCenterSignupLink:
    'Service Center Signup/Independent Technician Signup',
  employeeSignupMessage:
    'If you are an employee of a Service Center that is already registered in the AutoAuth system, inform your Service Center owner/manager of your user account username (found on the <userProfile> page). With your username, your Service Center owner/manager can invite you to join the Service Center. Once you are invited you will see a link on this page to join the Service Center.',
  employeeSignupMessage2:
    'Once these steps are completed you will be ready to start using all AutoAuth Certified Diagnostic tools registered in the Service Center with which you are associated.',
  paymentInfo: 'Payment Information',

  addingDirectory: 'Adding directory…',
  updatingDirectory: 'Updating directory…',
  directoryName: 'Directory Name',
  directoryId: 'Directory ID',
  directoryType: 'Directory Type',
  directoryState: 'Directory State',
  tokenIssuerName: 'Token Issuer Name',
  type: 'Type',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  serverUrl: 'Server URL',
  adfsDomain: 'ADFS Domain',
  adfsAdditionalFields: 'ADFS Additional Fields',
  redirectUrl: 'Redirect URL',
  authorizationPath: 'Authorization Path',
  tokenPath: 'Token Path',
  caCert: 'CA Certificate',
  textInput: 'Text Input',
  fileUpload: 'File Upload',
  file: 'File: ',

  manageDirectoryServices: 'Manage Directory Services',
  directoryServices: 'Directory Services',
  addDirectory: 'Add Directory',
  noDirectoryServices: 'No Directory Services',
  noRowsFound: 'No Rows Found',
  selectAUser: 'Select a user…',
  selectATool: 'Select a tool…',
  streetAddress1: 'Street Address 1',
  streetAddress2: 'Street Address 2',
  city: 'City',
  zipCode: 'Zip Code',
  phoneNumber: 'Phone Number',

  addingLink: 'Adding link…',
  requestInviteCode: 'Request Invite Code',
  requestingCode: 'Requesting code…',
  sendingLink: 'Sending link…',
  accountValidationLinkSent:
    'Account Validation link has been sent to the email address associated with:',
  pleaseCheckEmailForCode: 'Please check your email for the code.',
  verify: 'Verify',
  verifying: 'Verifying…',
  confirmNewPassword: 'Confirm New Password',
  resetPassword: 'Reset Password',
  usernameEmailNotFound: 'Username or Email Address not found',
  passwordHasBeenReset: 'Your password has been reset',
  requestReset: 'Request Reset',
  toolVendorSignup: 'Tool Vendor Signup',
  info: 'Info',
  custom: 'Custom',
  addServiceCenter: 'Add Service Center',
  user: 'User',
  confirmRemoveDirectoryFromShop: 'Confirm Remove Directory from Shop',
  areYouSureRemoveDirectory: 'Are you sure you want to remove this directory? ',
  canceling: 'Canceling…',
  customRange: 'Custom Range',
  apply: 'Apply',
  signupLinkAdded: 'Signup Link Added!',
  userChangedUserStateAtShop: 'User changed a user’s state at a shop',
  alreadyRegistered: 'Already registered?',
  enterNewPassword: 'Enter New Password',
  loginNow: 'LOGIN NOW',
  signupLink: 'Signup Link',
  LINK: 'LINK',
  userNotConfirmed:
    'User is not confirmed.  Please check your email for your account validation link.',
  pageNotFound: 'Sorry, page not found!',
  stepsForAARegistration: 'Steps for AutoAuth Registration',
  requestYourInviteCode: 'Request your Invite Code',
  useCodeToCreateAccount:
    'Use the Code received via email to create your User Account',
  loginToRegisterTools:
    'Log in to register your tools to begin unlocking vehicles',
  everyoneShouldFirstCreateAccount:
    'Everyone should first create a User Account',
  inviteCodeSubmitted:
    'Your Invite Code Request has been submitted.  You will receive an email with your invite code when approved.',
  goToLogin: 'Go To Login Page',
  fieldCannotBeBlank: 'Field cannot be blank',
  here: 'here',
  toGetStarted: 'to get started',
  toCompleteEmailAddressChange:
    'To complete your email address change, please enter the verification code we just sent to the new address.',
  shopSubscriptionCanceled: 'Shop Subscription has been canceled',

  directoryAdded: 'Directory Added!',
  directoryUpdated: 'Directory Updated!',
  signupLinkUpdated: 'Signup Link Updated!',
  success: 'Success',
  error: 'Error',
  usernameClientIdNotFound: 'Username/client id combination not found.',
  close: 'Close',
  directoryIdHint:
    'ID must be less than 30 characters and contain only letters and numbers.',
  tenantId: 'Tenant ID',

  errorRemovingTool: 'Error Removing Tool Serial #<serialNo>',

  // Months and Days
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  Sun: 'Sun',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',

  // v6
  currentTools: 'Current Tools',
  removedTools: 'Removed Tools',
  previousImports: 'Previous Imports',
  currentUsers: 'Current Users',
  removedUsers: 'Removed Users',
  editDirectory: 'Edit Directory',
  needAnAccount: 'Need an account?',
  register: 'register',
  shopProfile: 'Shop Profile',
  myProfile: 'My Profile',
  userLogin: 'User Login',
  cityTown: 'City/Town',
  country: 'Country',
  saveChanges: 'Save Changes',
  savingChanges: 'Saving Changes',
  about: 'About',
  contact: 'Contact',
  loginSteps: 'Your registration has been confirmed. Login now',
  forServiceCenters: 'For Service Centers & Independent Technicians',
  forTechnicians: 'For Technicians',
  addTools: 'Add Tools',
  removeTools: 'Remove Tools',
  addUsers: 'Add Users',
  bulkAddUsers: 'Bulk Add Users',
  currentPlan: 'Current Plan',
  subscription: 'Subscription',
  loginError: 'Login Error',
  getStarted: 'Get Started',
  shopAffiliations: 'Shop Affiliations',
  clickAccept: 'Accept',
  clickReject: 'Reject',
  loading: 'Loading',
  toolRemovedConfirmation: 'Tool has been removed.',
  removedUser: 'Removed User',
  location: 'Location',
  value: 'Value',
  viewPaymentHistory: 'View Payment History',
  userInformation: 'User Information',
  noUserOrToolActions: 'No User or Tool Actions',
  toolInformation: 'Tool Information',
  toolAction: 'Tool Action',
  tech: 'Tech',
  toolSerial: 'Tool Serial Number',
  toolManufacturer: 'Tool Manufacturer',
  upgradeToStandardPlusDescription:
    'Upgrade now and get Stats, Trends and Reports for your shop that will give you valuable insights into your business.',
  upgradeNow: 'Upgrade Now',
  comparePlans: 'Compare Plans',
  upgrade: 'Upgrade',
  bulkAddTools: 'Bulk Add Tools',
  bulkRemoveTools: 'Bulk Remove Tools',
  bulkToolImport: 'Bulk Tool Import',
  bulkTechnicianImport: 'Bulk Technician Import',
  unlimited: 'Unlimited*',
  authToolIndependentofUser: 'Auth. Tool, Independent of User',
  shopHistoryReports: 'Shop History Reports',
  technicianHistoryReports: 'Technician History Reports',
  toolHistoryReports: 'Tool History Reports',
  addlUsers: 'Add’l Users',
  extraToolForEachAddlUser: 'Extra tool for each add’l user',
  maxUsers: 'Max Users - ',
  maxTools: 'Max Tools - ',
  registeredTool: 'Registered Tool',
  standardCurrentPlan: 'Standard (Current Plan)',
  selectedPlan: 'Selected Plan',
  creditCardOnly: 'Credit Card Only',
  registrationFee: 'Registration Fee',
  chooseStandard: 'Choose Standard',
  upgradeForStatsAndTrends: 'Upgrade now and get access to your Stats & Trends',
  givesYouValuableInsights:
    'This gives you valuable insights into your business, allowing you to see',
  toolAndUserTotalsAtAGlance: 'your tool and user totals at a glance',
  totalUnlocksOverTime: 'total unlocks over time',
  vehiclesComingIntoYourShopInAGivenTimeFrame:
    'which vehicles are coming into your shop in a given time frame',
  whichTechAreDoingMostAndLeastUnlocks:
    'which Techs are doing the most the least unlocks',
  whichToolsAreBeingUsedTheMost: 'which tools are being used the most',
  whichToolsAreHavingTheMostErrors: 'which tools are having the most errors',
  upgradeNowAndGetAccessToShopReport:
    'Upgrade now and get access to your Shop Report.',
  shopReportIsSearchableAndFilterable:
    'The Shop Report is a searchable and filterable history log of ALL administrative actions in your shop.',
  youCanFindAnyAction: 'You can find any action to see who, when, and what.',
  seeWhichUsersHaveRespondedToAnInvite:
    'See which users have responded to an invite.',
  upgradeNowAndGetAccessToToolReport:
    'Upgrade now and get access to your Tool Report.',
  toolReportIsSearchableAndFilterable:
    'The Tool Report is a searchable and filterable log of ALL tool actions in your shop.',
  seeWhichToolsAndTechsAreHavingErrors:
    'See which tools and techs are having errors.',
  seeTheVehiclesAndCodesRead: 'See the vehicles and the codes read.',
  seeWhichTechsAreUsingToolsAfterHours:
    'See which Techs are using your tools after hours.',
  learnMore: 'Learn More',

  userDetails: 'User Details',
  goToShopProfile: 'Go To Shop Profile',
  selectAAction: 'Select an action',
  pickAction: 'Pick Action',
  resetFilters: 'Reset Filters',
  applyFilters: 'Apply Filters',
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  copiedLink: 'Copied Link',
  upgradeToEnterpriseToView: 'Upgrade Now to Enterprise Pro to view that page',
  pickVin: 'Pick Vin',
  selectAVin: 'Select a vin',
  upgrading: 'Upgrading...',
  autoauthStandard: 'AutoAuth STANDARD',
  autoauthStandardPlus: 'AutoAuth PLUS',
  autoauthEnterprisePro: 'AutoAuth Enterprise Pro',
  toolsPerShop: 'Tools Per Shop',
  usersPerShop: 'Users Per Shop',
  adminsPerShop: 'Admins Per Shop',
  shopOrGroupRegistration: 'Shop or Group Registration',
  onlineToolRegistration: 'Online Tool Registration',
  onlineTechnicianRegistration: 'Online Technician Registration',
  customNamesForTools: 'Custom Names For Tools',
  customNotesForTools: 'Custom Notes For Tools',
  willBeChargedUpgradingToPlus:
    'You will be charged $<amount>/year prorated for the amount of time left in your subscription. Then the full amount each additional year the subscription is active.',
  action: 'action',
  selectARole: 'Select a role…',
  TERMINATED: 'TERMINATED',
  PAST_DUE: 'PAST_DUE',
  leaveShop: 'Leave Shop',
  addToolsToYourShop:
    'Add tools to your shop so you can start servicing vehicles.',
  thisYear: 'This Year',
  lastYear: 'Last Year',
  acceptedInviteSuccess: 'Successfully accepted shop invite',
  rejectedInviteSuccess: 'Rejected shop invite',
  addAnExistingUser:
    'Add an existing AutoAuth user to your shop by using their exact username to search for them.',
  ifYouWantToAddUser:
    'If you want to add a user who doesnt have a free AutoAuth account yet, you can send them to this link to sign up:',
  enterUsername: 'Enter Username',
  noPaymentMethodForIncreasingUsers:
    'Shop Account has no payment method. Please add a payment method to increase number of users for your shop.',
  next: 'Next',
  previous: 'Previous',
  viewReports: 'View Reports',
  statsAndTrends: 'Stats & Trends',
  filters: 'Filters',
  technician: 'Technician',
  selectAnAction: 'Select an Action',
  myStatus: 'My Status',
  receivedToolError: 'Received Tool Error',
  addShopUsers: 'Add Shop Users',
  nextPaymentAmount: 'Next Payment Amount',
  signupConfirm: 'An Account Validation link has been sent to <email>',
  backToRegister: 'Back To Register',
  toolSerialNumber: 'Tool Serial Number',
  myToolActivity: 'My Tool Activity',
  myShopActivity: 'My Shop Activity',
  afterValidatingLogin: 'After validating your account, you can log in.',
  noDataYet: 'No Data Yet',
  reachedMaxUsers:
    'You have used <userCount> of the maximum <maxUserCount> users available. <contactLink> for more information.',
  dontHavePermission: 'You do not have permission to access this page',
  singleUser: 'Single User',
  bulkUsers: 'Bulk Users',
  numUsersInvited: '<number> users invited',
  addToolsAndUsers:
    'Add tools and users to your shop so you can start servicing vehicles.',
  clearAllAndReupload: 'Clear All and Reupload',
  plus: 'plus',
  toolLimitMessage:
    'Only up to 400 tools can be imported or removed at one time. Please upload a file with fewer tools.',
  errorAddingTool: 'Error adding tool to shop',
  errorAddingTools: 'Error adding tools to shop',
  errorRemovingTools: 'Error removing tools from shop',
  errorEditingTool: 'Error editing tool',
  errorEditingTools: 'Error editing tools',
  featureOnlyOnPlus: 'This feature is only available with a <type> subscription!',
  comingSoon: 'Coming Soon',
  INVITED: 'INVITED',
  myActivity: 'My Activity',
  last7Days: 'Last 7 Days',
  validity: 'Validity',
  createdBy: 'Created By',
  copyLink: 'Copy Link',
  users: 'Users Per Shop',
  VALID: 'VALID',
  dashboardControls: 'Dashboard Controls',
  howToResolveChargeback:
    'The quickest way to resolve this and ensure no interruption of service is for the cardholder to contact the bank and let them know the charge was valid. If you have any questions, please contact us.',
  removing: 'Removing...',
  accept: 'Accept',
  recommended: 'Recommended',
  toolIsRegistered: 'Tool is Registered?',
  toolsOfMax: '<tools> of <maxTools> Maximum Shop Tools',
  unableToFindAddress: 'Unable to find address. Please check and try again.',

  // Audit Log Actions
  userCreatedTheirAcc: 'User created their account',
  userConfirmedTheirAcc: 'User confirmed their account',
  userImportedNewUsers: 'User imported a set of new users',
  userBulkInvitedUsers:
    'User performed a bulk invite for a set of users to join a shop',
  userRevokedInvite: 'User revoked an invite for a user to join a shop',
  userUpdatedInfo: 'User updated their info',
  userCreatedShop: 'User created a shop',
  userUpdatedShop: 'User updated a shops info',
  userUpdatedPaymentInfo: 'User updated a shops payment info',
  userRemovedPaymentInfo: 'User removed a shops payment info',
  userUpdatedMaxCap: 'User updated a shops max user capacity',
  userAddedUser: 'User added a user to a shop',
  userRemovedUser: 'User removed a user from a shop',
  userRespondedToInvite: 'User responded to a shop invitation',
  userChangedUserState: 'User changed a users state at a shop',
  userRegisteredTool: 'User registered a tool for a shop',
  userRemovedTool: 'User removed a tool from a shop',
  userImportedTools: 'User imported a set of tools into a shop',
  userLoggedInTool: 'User logged in via a tool',
  userADInitiateTool: 'User performed an ADInitiate via a tool',
  userADRequestTool: 'User performed an ADRequest via a tool',
  userADExecuteTool: 'User performed an ADExecute via a tool',
  userCreatedSignupLink: 'User created a signup link for users to join shop',
  userUpdatedSignupLink: 'User updated a signup link',
  userErrorADInitiateTool: 'User got an error on ADInitiate via a tool',
  userErrorADRequestTool: 'User got an error on ADRequest via a tool',
  userErrorADExecuteTool: 'User got an error on ADExecute via a tool',
  shopSubscriptionStateChanged:
    'Shop subscription state was changed by an automated process',

  // Stats & Trends
  userTotalOfMax : "User Total of <amount> Max",
  toolTotalOfMax : "Tool Total of <amount> Max",
  vehicleUnlocksMost : "Vehicle Unlocks - Most",
  toolTotalsVendorModel : "Tool Totals by Vendor[Model]",
  toolUnlocksByTechMost : "Tool Unlocks by Tech - Most",
  toolUnlocksByToolMost : "Tool Unlocks by Tool - Most",
  toolUnlocksForDay : "Tool Unlocks for Day",
  toolUnlocksByDay : "Tool Unlocks by Day",
  toolUnlocksByMonth : "Tool Unlocks by Month",
  forTheDayOf : "For the day of <date>",
  forTheMonthOf : "For the month of <month>",
  forTheYearOf : "For the year of <year>",


  // Receipt v2
  finalDetailsForOrderId: 'Final Details For Order #<id>',
  printPageForYourRecords: 'Print this page for your records',
  orderPlaced: 'Order Placed',
  autoAuthOrderNumber: 'AutoAuth.com Order Number',
  orderTotal: 'Order Total',
  itemsOrdered: 'Items Ordered',
  price: 'Price',
  paymentMethod: 'Payment Method',
  lastDigits: 'Last Digits',
  billingAddress: 'Billing Address',
  itemSubtotal: 'Item Subtotal',
  totalBeforeTax: 'Total before tax',
  estimatedTaxToCollect: 'Estimated tax to be collected',
  grandTotal: 'Grand total',
  toViewOrderStatus: 'To view the status of your order, return to Order Summary',

  // Tool Recovery
  toolRecovery: "Tool Recovery",
  fileTypeNotSupported : "This file type is not supported. Please try again with the following file types: JPG, PNG",
  fileTooBig: "This file is too large. Please try again with a file smaller than 10MB",
  ifSureOwnTool : "Please ensure you are trying to register using a tool's serial number. To confirm the serial number, please contact your tool's manufacturer or company. If you are sure you own this tool, please click 'Recover Tool'.",
  recoverTool : "Recover Tool",
  uploadRecoveryInstructions : 'Instructions: Upload a picture of your tool, <span class="color-aa-red u-font-weight-bold">with its SERIAL NUMBER clearly visible</span>. Once we verify that you are the owner, you will receive an email notice and will be able to add your tool.',
  uploadPictureOfSerial : "Upload Picture of Serial",
  dragAndDropImageHere : "Drag and Drop Image Here",
  submitRequest : "Submit Request",
  submitting : "Submitting...",
  recoveryRequestSubmitted : "Your request for tool recovery has been submitted! Please allow <num> business days for it to process. We will contact you via your email, <email>, when your request has been reviewed.",
  recoveryRequestFailed: "We were unable to submit your tool recovery request. Please try again, or contact support directly.",

  // v8
  errorToolRecovery: 'Something went wrong. Please try again. If error persists, please contact AutoAuth support team.',
  autoUpgradeNeeded: "Your current user and/or tool count exceeds the new terms for AutoAuth STANDARD and will be automatically upgraded to AutoAuth PLUS on your renewal date.  (See below for new terms)  If you do not wish to be upgraded please lower your user and/or tool counts down to the STANDARD shop limits before your renewal date.",
  helpImproveAutoAuth: "Help us improve AutoAuth by answering some questions and get a $20 gift card.",
  new: 'New',
  toolRevADAS: 'Tool Reviews / ADAS',
  standardReachedMaxUsers: 'Your AutoAuth STANDARD subscription is limited to a maximum of <maxUserCount> users. You may <upgradePlus> to increase the maximum user capacity.',
  saveOems: "Save Manufacturers",
  savingOems: "Saving Manufacturers",
  confirmOEMRegions: "Confirm Change Manufacturers/Regions Change",
  areYouSureYouWantToChangeOEMRegions: "Are you sure you want to change Manufacturers/Regions to:",
  areYouSureYouWantToRemoveOEMRegions: "Are you sure you want to remove the following Manufacturer:",
  oemRegionExplanation: "You will be able to service this manufacturers' vehicles until the renewal of your subscription on <date>. Afterward, you will no longer to be able to service those vehicles.",
  noPaymentMethodForChangingOEMRegion:
    'Shop Account has no payment method. Please add a payment method to change Manufacturers/Regions for your shop.',
  willBeChargedChangingOEMRegion:
    'You will be charged $<chargeToday>/year prorated for the amount of time left in your subscription. Then $<amount> will be added each additional year the subscription is active.',
  willBeCreditedChangingOEMRegion:
    'You will be credited $<amount>/year prorated for the amount of time left in your subscription. Then your subscription price will be reduced the full amount each additional year the subscription is active.',
  subscriptionPriceReductionOEMRegion:
    'Your subscription price will be reduced by $<amount> each additional year the subscription is active.',
  subscriptionPriceRemainsForSame: "You will not be charged today and your subscription price will remain the same.",
  OEMRegionsUpdated: "Manufacturers / Regions updated.",
  upgradeOEMRegionProration: 'Manufacturers/Regions Upgrade (prorated)',
  youHaveBeenChargedAmount: "You have been charged $<amount> today.",
  youHaveBeenCreditedAmount: "You have been credited $<amount> today.",
  confirmReinstateOEMRegions: "Confirm Reinstating Manufacturers/Regions",
  areYouSureYouWantToReinstateOEMRegions: "Are you sure you want to add/reinstate the following Manufacturers:",
  noChargeTodayAddedPriceOEMRegions: "You will not be charged today.  $<amount> will be added to each additional year the subscription is active.",
  oemRegionReinstatedExplanation: "You will be able to unlock these manufacturers' vehicles for the life of your subscription.",
  subscriptionPrice: "Subscription Price",
  subscriptionCredit: "Subscription Credit",
  downgradeOEMRegionProrationCredit: 'Manufacturers/Regions Downgrade (prorated)',
  regionCantBeChanged: "Note: Region cannot be changed after shop creation.",
  oemCompatibility:" Manufacturers Compatibility by Region",
  oemRegion: "Manufacturers / Regions",
  oems: "Manufacturers",
  region: "Region",
  activeOems: "Auto Manufacturers",
  oemsMarkedForRemoval: "Manufacturers Expiring Upon Renewal Date",
  inactiveOEMs: "Inactive/Expiring Manufacturers",
  addOems: "Add/Reinstate Manufacturers",
  addingOems: "Adding Manufacturers",
  removeOems: "Remove Manufacturers",
  removingOems: "Removing Manufacturers",
  expiresOn: "Expires on <date>",
  pickShopRegion: "Pick Shop Region",
  euSnapCompability: "This region is currently limited to specific Snap-on/Sun tools sold in Europe. Please check with Snap-on/Sun to ensure your tool is supported.",
  pastDue: "PAST DUE",
  messageBoard: "Message Board",
  myInteraction: "My Interaction",
  delete: "Delete",
  block: "Block",
  unblock: "Unblock",
  lock: "Lock",
  unlock: "Unlock",
  activeUntilRenewal: "(Active until <date>)",
  minimumNumberOfOEMs: "Shop is at the Minimum Number of Manufacturers Per Shop",
  cannotRemoveLastOEM: "Your shop must have at least one Manufacturer.",
  mbBaner:
  "Available to everyone for a limited time.  <upgradePlus> to continue to use AutoAuth Message Board uninterrupted",
  sortBy: 'Sort by...',
  newest: 'Newest',
  highestRated: 'Highest Rated',
  noteworthy: 'Noteworthy',
  goBack: 'Go Back',
  thread: 'Post',
  threads: 'Posts',
  create: 'Create',
  clear: 'Clear',
  createThread: 'Create Post',
  cancelThread: 'Cancel Post',
  clearSearch: 'Clear Search',
  searchThread: 'Search Post',
  threadTitle: 'Post Title',
  startThread: 'Create a Post',
  threadDescription: 'Enter your post description',
  tag: 'Tag',
  tags: 'Tags',
  threadTags: 'Post Tags',
  threadDetails: 'Post Details',
  tagsSelected: "Click 'Filter By Tags' to Add Tags Here",
  clearTags: 'Clear Tags',
  filterByTags: 'Filter by Tags',
  loadMore: 'Load More',
  noMoreThreads: 'No more posts',
  addComment: 'Add a Comment',
  addReply: 'Reply',
  cancelComment: 'Cancel Comment',
  cancelReply: 'Cancel Reply',
  closeReplies: 'Close Replies',
  showReplies: 'Show Replies',
  comment: 'Comment',
  reply: 'Reply',
  report: 'Report',
  postNoComments: 'This comment does not have any replies yet',
  threadNoComments: 'This post does not have any comments yet',
  confirmDeleteContent: 'Are you sure you want to delete this <content>? This action cannot be undone',
  commentReported: 'Comment successfully reported',
  replyReported: 'Reply successfully reported',
  threadReported: 'Post successfully reported',
  postAlreadyReported: 'You already reported this comment',
  postReportReason: 'What is the reason for reporting this comment?',
  contentReportReason: 'What is the reason for reporting this <content>?',
  threadReportReason: 'What is the reason for reporting this post?',
  describeReport: 'Describe the reason for your report',
  reportValidateError: 'The description must be at least <num> characters long',
  selectOneCheckboxError: 'Please select at least one of the following options',
  agree: 'Agree',
  threadDoesNotExist: 'The thread you are looking for may have been deleted or never existed',
  lockedThreadMessage: 'Locked Content. Replies and Votes have been restricted for this content',
  lockedContent: "Locked Content",
  views: 'Views',
  likes: 'Likes',
  dislikes: 'Dislikes',
  comments: 'Comments',
  modifyThread: 'Modify Post',
  threadDeleted: 'Post successfully deleted',
  commentDeleted: 'Comment successfully deleted',
  replyDeleted: 'Reply successfully deleted',
  contentSuccessfullyDeleted: '<content> successfully deleted',
  title: 'Title',
  body: 'Body',
  userNoThreads: '<userName> has not created any Posts yet',
  userNoComments: '<userName> has not posted any Comments yet',
  youNoThreads: 'You have not created any Posts yet',
  youNoComments: 'You have not posted any Comments yet',
  mbSpam: 'Spam',
  mbOffensive: 'Offensive or Inappropriate Content',
  mbViolation: 'Violation of Terms and Conditions',
  mbTrolling: 'Trolling or Flamebaiting',
  mbMisinformation: 'Misinformation or False Content',
  mbInappropriate: 'Inappropriate Advertising',
  mbDuplicate: 'Duplicate or Reposted Content',
  mbPrivacy: 'Privacy Concerns',
  mbOther: 'Other',
  notification: 'Notification',
  userMuted: 'Your account has been muted. You will be unable to interact with other users or posts until further notice',
  currentlyMuted: '<userName> is currently muted (unable to interact with other users or posts until further notice)',
  userNotExist: 'The user you are looking for have been removed or never existed.',
  tagServices: 'Discussions related to various automotive services available',
  tagParts: 'Discussions about different auto parts and their compatibility',
  tagRepairs: 'Conversations focused on repairing specific vehicle issues',
  tagAutoShop: 'Topics related to auto shops, including reviews, recommendations, and experiences',
  tagTools: 'Discussions on automotive tools, their usage, and recommendations',
  tagMechanics: 'Conversations about professional mechanics, their expertise, and advice',
  moderator: 'Moderator',
  admin: 'Admin',
  contentDeleted: 'Content Deleted',
  modalMessageTermsCondition: "We have recently implemented new Terms and Conditions. To ensure compliance and the best experience for all users, we kindly request that you review and agree to the updated terms before accessing and using the AutoAuth Message Board. By clicking 'Agree' below, you acknowledge that you have read and understood the new Terms and Conditions. If you have any questions or concerns regarding the changes, please feel free to contact us.",
  errorTryAgain: "An error has occurred. Please try again. If the problem persists, contact our support team for assistance. Thank you for your patience.",
  chargeBackShopNotification: 'Chargeback Shop Notification',
  chargeBackShopMessage: 'Shops that have a history of chargebacks are required to contact the AutoAuth support team to initiate the process of reinstating their shop.',
  autoAuthRepresents:
    'AutoAuth represents a family of authentication services that allow our Independent Service Shops to perform authenticated dignostics on vehicles using their existing scan tools through a secure gateway.',
  autoAuthStandardOption:
    '<AutoAuthStandard> Budget entry-level authenticated diagnostic service for individual service shops.',
  autoAuthPlusOption:
    '<AutoAuthStandardPlus> Recommended authenticated diagnostic service contains detailed reports and troubleshooting which results in more efficient sevice experience.',
  autoAuthEnterpriseOption:
    '<AutoAuthEnterprise> Premium authenticated diagnostic service includes all Plus features while integrating directly into a customers Identity Management System for Enterprise shops with more than 500 shops or 1000 tools.',
  identityManagementSystem: 'Identity Management System',
  aasoShort: 'Basic authenticated diagnostics service',
  aapoShort: 'Authenticated diagnostics service + detailed reports and troubleshooting',
  aapcoShort: 'Authenticated diagnostics service + detailed reports and troubleshooting + customer management',
  aaeoShort: 'All Plus features + Identity Management System and Bulk Import',
  toryMReview: "The features in AutoAuth PLUS save me 5-6 hours a week. It's all about efficiency.",
  upgradeToStandardPlus: 'Upgrade To AutoAuth PLUS',
  chooseStandardPlus: 'Choose AutoAuth PLUS',
  purchaseOrderOnly: 'Purchase Order Only',
  standardPlusFeatures: 'AutoAuth PLUS Features',
  techUpgradeToStandardPlusDescription:
    'AutoAuth PLUS will give you, the Technician, a log of all your past tool actions.',
  wantToUpgradeToPlus:
    'Are you sure you want to upgrade to AutoAuth PLUS?',
  upgradeToStandardPlusDescriptionAlt:
    'AutoAuth PLUS will give you, the Owner, a log of all your past shop actions.',
  techUpgradeToStandardPlus: 'Ask your Shop Owner to upgrade to AutoAuth PLUS',
  plusUpgrade: 'Shop Upgrade to AutoAuth PLUS (prorated)',
  userInfoError: 'Login Failed. We were unable to get your user information',
  invalidResetCode: 'Invalid code provided, please request a code again.',

  // v9
  deleteReason: 'What is the reason for deleting this content?', // Only shown to admin/moderator
  showOriginalContent: 'Show Original Content',
  confirmFeat: 'Are you sure you want to Feature this content?', // Only shown to admin/moderator
  repayDisputedPayment: "Repay Disputed Charge",
  chargebackLost: 'Your most recent payment was disputed by the credit card holder. Your shop has been <state>. To resolve this issue and reactivate your account please repay the disputed payment on the Shop Profile page.',
  chargebackDisputed: 'Your most recent payment was disputed or questioned by the credit card holder. To avoid loss of functionality you must correct this issue. The quickest way to resolve this and ensure no interruption of service is for the cardholder to contact the bank and let them know the charge was valid. If you have any questions, please contact us at <link>',
  consentToRepayPlusFifteen: 'In order to reinstate your shop you must consent to repay the Disputed Payment Amount + Chargeback Fee ($15)',
  doYouConsent: "Do you consent to be charged  $<amount> + $15.00 = $<chargeAmount> to reinstate your shop?",
  processing: "Processing...",
  stopEmailNotification: 'Stop Email Notifications',
  enableEmailNotification: 'Enable Email Notifications',
  whenIsEmailSend: 'You will receive an email notification when someone replies to a Post or Comment you have previously created',
  noLongerEN: 'You will no longer receive an email notification when someone replies to a Post or Comment you have previously created',
  shopUsersCSV: 'Shop Users CSV',
  shopToolsCSV: 'Shop Tools CSV',
  toolsCSVExported: 'Tools have been exported',
  usersCSVExported: 'Users have been exported',
  noPaymentMethodToRepayDisputedPayment: 'Shop Account has no payment method. Please add a payment method to repay your disputed payment so that you can reinstate your AutoAuth account.',
  confirmRepaymentDisputedPayment: 'Confirm Repayment of Disputed Charge',
  repayingPayment: 'Repaying...',
  chargingRepayment: 'Submitting Repayment...',
  shopReinstated: 'Your shop has been reinstated.',
  offensiveContent: 'Your content appears offensive. Please change or rephrase it to follow our guidelines. Repeated misconduct may result in muting',
  disputedPaymentRepay: 'Disputed Charge Repayment',
  resetPasswordMessage: 'Your AutoAuth account password has been reset. Please <span class="color-aa-red u-font-weight-bold">update your tools credentials</span> immediately to ensure proper functionality',
  reachYouToolManufacturer: 'Please reach out to your tool manufacturer if you need assistance entering your new AutoAuth password on your tools',
  furtherActionRequired: 'Further Action Required',
  userInteraction: 'User Interaction',
  downgradeToStandard: 'Downgrade To STANDARD',
  cancelDowngradeToStandard: 'Cancel Downgrade To STANDARD',
  downgrading: 'Downgrading...',
  cancelingDowngrading: 'Canceling Downgrading...',
  cancelDowngradeSuccessful: 'Your shop downgrade has been canceled.',
  confirmDowngradeSubscription: 'Confirm Downgrade AutoAuth Subscription to STANDARD',
  confirmDowngradeSubscriptionMessage: 'Are you sure you want to downgrade your AutoAuth subscription to STANDARD? Doing so you will still be able to view your Stats, Reports & Logs until your current year subscription expires. However, after that date you will no longer be able to do so.',
  downgradeDelayedSuccessful: 'Your shop will be downgraded to STANDARD on your renewal date',
  downgradeSuccessful: 'Your shop has been downgraded to STANDARD',
  tokenExpired: "Your session may have expired. Please log back in.",
  toolNoLongerOwnedByShop: "This tool in not owned by your shop.",
  upgradeCapacity: 'Upgrade Capacity',
  upgradeUsersToolsCapacity: 'Upgrade Users/Tools Capacity',
  addShopUsersTools: 'Add Shop Users/Tools',
  upgradeToPlusToIncreaseCapacity: "<upgradePlus> to increase shop capacity.",
  maximumShopToolsReached: 'Maximum Shop Tools Reached',
  autoUpgrade: "Auto Upgrade",
  userUpgradedToPlus: 'User upgraded shop type to PLUS',
  userRepayedCanceledPayment: 'User repayed canceled payment to reinstate shop',
  userMarkedShopForDowngrade: 'User marked shop for downgrade to STANDARD',
  userCanceledDowngradeShop: 'User canceled shop downgrade to STANDARD',
  downgradeDisableExplain: 'Shop user and tool counts must be at or below STANDARD level and payment must be current to be eligible to downgrade.',
  requestHasBeenSent: 'Invitation has been sent. Invited user must log in and accept invite to activate the account and use tools under this subscription.',
  oemUpchargeExplain: 'Added Manufacturer rate is $<base> base price plus $<upcharge> per added user/tool above base capacity.',
  userUpchargeExplain: 'Added User/Tool rate is $<base> base price plus $<upcharge> per added manufacturer above base capacity.',
  renewalUpchargeExplain: 'Added User/Tool price is $<base> base price plus $<upcharge> per added manufacturer above base capacity.',
  addedOem: 'Added Manufacturer',
  addedUser: 'Added User/Tool',
  addedTechCert: 'Added Tech Certification',
  upgradeTechCertProration: 'Tech Certification Capacity Upgrade (prorated)',
  taxToCollect: 'Tax to be collected',
  subtotal: 'Subtotal',
  rate: 'Rate',
  note: 'Note: ',
  noteForPlus: 'Note for PLUS Subscription: ',
  upgradeToPlusToolActions: 'Upgrade now for detailed tool logs and error reports to boost productivity.',
  upgradeToPlusToolNotes: 'Upgrade now and add custom notes to individual tools, allowing you to assign technicians, provide instructions or safety information for tool usage, and streamline operations effortlessly.',
  customizeYourTools: 'Customize your Tools with Unique Names',
  upgradeToPlusToolNames: "Add custom names to your tools and conveniently track your tools' activity with the ability to search for specific tools by name.",
  userReinstatedShopOems: "User added new Manufacturers to shop and/or reinstated Manufacturers marked for removal at next renewal date",
  userRemovedShopOems: "User removed a Manufacturer from a shop",
  userBecameCertifiedTech: "User became a Certified Tech",
  userUpdatedShopTechCertCapacity: "User updated a shop's max tech certifications capacity",
  userEditedToolForShop: 'User edited a tool for a shop',
  userInitiatedToolRecovery: 'User initiated a tool recovery',
  userCreatedCRMCustomer: 'User created a new customer',
  userUpdatedCRMCustomer: 'User updated a customer',
  userCreatedCRMEvent: 'User created an event',
  userUpdatedCRMEvent: 'User updated an event',
  userDeletedCRMEvent: 'User deleted an event',
  userCreatesCRMVehicle: 'User created a new Vehicle',
  userRemovedCRMVehicle: 'User removed a vehicle',
  userUpdatedCRMVehicle: 'User updated a vehicle',
  userCreatedCRMOrder: 'User created a new order',
  userUpdatedCRMOrder: 'User updated an order',
  userAssignedNewTechnicianCRMOrder: 'User assigned a new technician to an order',

  // Price Breakdown table in Shop Profile
  priceBreakdown: 'Price Breakdown',
  unitPrice: 'Unit Price',
  quantity: 'Quantity',
  total: 'Total',
  shopUsersTools: 'Shop Users/Tools',
  added: 'Added:',
  included: 'Included:',
  freeCaps: 'FREE',
  renewalPrice: 'Renewal Price:',
  perUserRateExplain: 'The base price per added user/tool is $20, plus $10 added for every additional manufacturer.',


  // Owner Authorization branch
  generateD1Form: 'Generate D1 Form',
  d1FormDownloaded: 'D-1 Form has been downloaded. Check your downloads folder.',
  verificationRequestCompleted: 'Verification Request Completed',
  vehicleOwnerVerification:'Vehicle Owner Verifications (D1 Form)',
  ownerVerifications: 'Owner Verifications',
  requestVerfication: 'Request Verfication',
  requestVerification: 'Request Verification',
  cancelRequest: 'Cancel Request',
  searchBy: 'Search by',
  searchForClient: 'Search for Client',
  sendRequest: 'Send Request',
  requestSent: 'The request has been sent',
  requestCreated: 'The request has been created',
  contactPreference: 'Contact Preference',
  chooseContactPreference: 'Choose Contact Preference',
  both: 'Both',
  requestVerificationExists: 'The Verfication request already exists',
  currentOwnerVerficationRequests: 'Current Owner Verfication Requests',
  noVerificationRequests: 'No Owner Verification Requests',
  additionalDetails: 'Additional details',
  techCertification: 'AutoAuth Certified Technician Status',
  AACertifiedTech: 'AutoAuth Certified Technician',
  notCertified: 'Not Certified',
  becomeCertified: 'Submit AutoAuth Certification Request',
  becomingCertified: 'Becoming Certified...',
  verifyId: 'Verify Your ID',
  verifyingId: 'Verifying ID',
  idVerified: 'ID Verified',
  idMustBeVerified: 'ID must be verified to be AutoAuth Certified',
  verificationFailed: "Identity couldn't be verified",
  youAreCertified: 'Congratulations!  Your AutoAuth Cerification is Approved.  You are now an AutoAuth Cerified Technician',
  certified: 'Certified',
  notCertified: 'Not Certified',
  phoneHasBeenVerified: 'Phone number has been verified',
  needsVerification: 'Needs Verification',
  phoneVerificationCodeSent: 'A verification code has been sent to phone number: <phone>',
  verifyPhoneNumber: 'Verify phone number',
  resendCode: 'Resend Code',
  sendCode: 'Send Verification Code',
  sendingCode: 'Sending Confirm Code',
  ConfirmCodeResent: 'Confirmation Code has been resent',
  toCompletePhoneNumberVerification: 'Click the link below to send a verification code to the listed phone number.',
  toCompletePhoneNumberChange: 'To complete your phone number change, click the link below to send a verification code to the new phone number.',
  phoneIsOptOut: "We noticed your phone number is opted out, which means you won't receive important text notifications.",
  pleaseOptIn: "Please update your phone number or opt-in to start receiving notifications.",
  optInSuccess: 'Success. You will begin receiving messages again',
  emailOrPhoneNeedVerification: 'Your email address and/or phone number need to be verified. Please click the button below to verify these fields.',
  verificationHint: 'Verify your ID and personal text-capable phone number to become an AutoAuth Certified Technician.',
  verificationCode: 'Verification Code',
  verified: 'Verified',
  submitCode: 'Submit Verification Code',
  submittingCode: 'Submitting Code',
  month: 'month',
  billedYearly: 'billed yearly',
  vehicleOwner: 'Vehicle Owner',
  vehicleOwnerInformation: 'Vehicle Owner Information',
  vehicleInformation: 'Vehicle Information',
  vehicleMake: 'Vehicle Make',
  vehicleModel: 'Vehicle Model',
  vehicleYear: 'Vehicle Year',
  vehicleColor: 'Vehicle Color',
  vehicleOdometer: 'Vehicle Odometer',
  vehicleLPN: 'Vehicle License Plate Number',
  vehicleStateRegistration: 'State of Vehicle Registration',
  vehicle: 'Vehicle',
  detailedOwnerVerification: 'Detailed Owner Verification (D1 Form)',
  streetAddress: 'Street Address',
  driversLicenseNumber: "Driver License Number",
  driversLicenseState: "Driver License State",
  driversLicenseExpirationDate: "Driver License Expiration Date",
  shopInformation: 'Shop Information',
  serviceProviderBusinessName: "Service Provider Business Name",
  technicianName: 'Technician Name',
  validateVIN: 'The VIN must be validated in order to send the request',
  waitingVehicleOwner: "Waiting on vehicle owner to submit verified info",
  waitingTechInput: "Waiting on Technician to submit",
  verifyPhoneNumber: 'Verify Phone Number',
  servicePerformed: 'Service Performed',
  selectService: 'Select Service',
  service: 'Service',
  keyCutFromCode: 'Key Cut from Code',
  immobilizerReset: 'Immobilizer Reset',
  other: 'Other',
  notAvailable: 'Not available',
  areYouSureRejectVerification: 'Are you sure you want to reject this verification request?',
  confirmRejectVerificationRequest: 'Confirm Reject Verification Request',
  verificationRejectedBy: 'This Verification Request has been rejected by the <rejectedBy>',
  KEYCUT: 'Key Cut from Code',
  IMMO: 'Immobilizer Reset',
  rejectedBy: 'Rejected by <rejectedBy>',
  PENDING_DRIVER_ACCEPTANCE: 'Pending Vehicle Owner Acceptance',
  PENDING_DRIVER_INFO_AND_TECH_VEHICLE_DETAILS: 'Pending Vehicle Owner Information and Technician Vehicle Details',
  PENDING_DRIVER_INFO: 'Pending Vehicle Owner Information',
  PENDING_TECH_VEHICLE_DETAILS: 'Pending Technician Vehicle Details',
  PENDING_TECH_AND_DRIVER_SIGNATURES: 'Pending Vehicle Owner and Technician Signatures',
  PENDING_TECH_SIGNATURE: 'Pending Technician Signature',
  PENDING_DRIVER_SIGNATURE: 'Pending Vehicle Owner Signatures',
  COMPLETED: 'Completed',
  OWNER_SIGN_REQUIRED: 'Pending Shop Owner Signature',
  CANCELED_BY_DRIVER: 'Canceled by Vehicle Owner',
  CANCELED_BY_TECH: 'Canceled by Technician',
  REJECTED_BY_DRIVER: 'Rejected by Vehicle Owner',
  REJECTED_BY_TECH: 'Rejected by Technician',
  signaturesAndDates: 'Signatures and Dates',
  signAndDate: 'Sign and Date',
  vehicleOwnerSignature: 'Vehicle Owner Signature',
  techSignatureDate: 'Technician Signature and Date',
  driverSignatureDate: 'Vehicle Owner Signature and Date',
  verificationCompleted: 'The verification has been completed',
  showOwnerSignOnly: "Show Only 'Pending Shop Owner Signature'",
  hideCompleted: 'Hide Completed',
  hideRejected: 'Hide Rejected',
  submitSignature: 'Submit Signature',
  technicianSignature: 'Technician Signature',
  technicianSignedDate: 'Technician Signed Date',
  driverSignedDate: 'Vehicle Owner Signed Date',
  submit: 'Submit',
  save: 'Save',
  shopOwnerSignatureDate: 'Shop Owner Signature and Date',
  shopOwnerSignature: 'Shop Owner Signature',
  shopOwnerSignedDate: 'Shop Owner Signed Date',
  lsid: 'LSID',
  lockSmithID: 'Locksmith ID Number',
  odometerInputError: 'Please enter a valid odometer reading using only numeric values',
  vinInfoNotFound: 'VIN verification is necessary for this process. Please double-check the entered VIN for accuracy.',
  upgradeToStandardPlusD1Description:
    'Upgrade now to get access to seamless Vehicle Owner Verification and D1 form auto-generation',
  upgradeForOwnerVerification: 'Upgrade now and get access to Vehicle Owner Verification (D1 form)',
  becomeAutoAuthCertified: 'Become an AutoAuth Certified Technician',
  becomeAutoAuthCertifiedToCreateVehicleVerifications: 'Complete the certification process in order to process Vehicle Owner Verifications.',
  recoverVerification: 'Recover Verification Request',
  confirmRejection: 'Confirm Rejection',
  verificationRejected: 'Vehicle Owner Verification has been rejected',
  verificationRecovered: 'Vehicle Owner Verification has been recovered',
  verificationStatus: 'Verification Status',
  verifyIdentityInPerson: 'Verify identity in person',
  cancelViip: 'Cancel verify identity in person',
  viipDisclosure: 'By proceeding, you acknowledge and agree to undertake the responsibility of personally verifying that the person requesting the service, the provided identity, and the vehicle owner are one and the same, ensuring legitimacy. This verification process is crucial for security and compliance purposes, and your diligence in confirming the alignment of these details is essential.',
  viipCheckBoxDisclosure: 'I hereby confirm that I have personally verified the identity of the person requesting the service, ensured the provided identity aligns with the vehicle owner, and undertaken the necessary steps to validate their legitimacy.',
  requiredViipCheck: "Checkbox 'Verify Identity in Person'",
  requiredFields: 'Required fields',
  pleaseEnterSignature: 'Please enter signature',
  vinHasBeenVerified: 'VIN has been verified',
  shrunkResultSearch: 'Too many results! Displaying the latest 100. Please refine your search for more specific results',
  compatibility: 'Compatibility',
  genericCompability: "Please check with your tool manufacturer to ensure your region and vehicle manufacturer(s) are supported by your tool(s).",
  checkRegionManufacturerCompatibility: 'Tool/Vehicle Compatibility by Region',
  vehicleToolCompatibility: 'Check Tool/Vehicle Compatibility',
  manufacturerCompatibilityByRegion: 'Manufacturer Compatibility by Region',
  disclaimerCompatibilityChart : "DISCLAIMER: Green checkmark does not guarantee compatibility with every tool from the tool manufacturer.",
  shopID: 'Shop ID',
  userState: 'User State',
  userRole: 'User Role',
  toolName: 'Tool Name',
  toolNotes: 'Tool Notes',
  toolNameEdited: 'Tool Name was edited',
  toolNotesEdited: 'Tool Notes were edited',
  numberAdded: 'Number Added',
  addedTechCerts: 'Added Tech Certifications',
  response: 'Response',
  editedUser: 'Edited User',
  userAdded: 'User Added',
  updatedOems: 'Updated Manufacturers',
  reinstatedOems: 'Added/Reinstated Manufacturers',
  addedUsers: 'Added Users/Tools',
  removedOems: 'Removed Manufacturer',
  removed: 'Removed',
  reinstatedManufacturers: 'Reinstated Manufacturers',
  addedOems: 'Added Manufacturers',
  userReinstatedOems: 'User reinstated Manufacturers marked for removal at next renewal date',
  userAddedOems: 'User added new Manufacturers to shop',
  userAddedAndReinstatedOems: 'User added new Manufacturers to shop and reinstated Manufacturers marked for removal at next renewal date',
  columns: 'Columns',

  //CRM
  manageCustomers: 'Manage Customers',
  manageVehicles: 'Manage Vehicles',
  calendar: 'Calendar',
  addService: 'Add Service',
  addCustomer: 'Add Customer',
  createNewCustomer: 'Create New Customer',
  createNewVehicle: 'Create New Vehicle',
  selectTechnician: 'Select Technician',
  selectTechnicianHint: 'You Must Select a Technician',
  customer: 'Customer',
  selectCustomerHint: 'You Must Select a Customer',
  searchCustomer: 'Search Customer',
  customerInformation: 'Customer Information',
  orderInformation: 'Order Information',
  phoneNumberHint: 'Enter a valid phone number',
  newCustomerAdded: 'New Customer Added',
  newCustomerCreated: 'New Customer Created',
  newOrderCreated: 'New Order Created',
  orderUpdated: 'Order Updated',
  customerUpdated: 'Customer Information Updated',
  viewCustomerInformation: 'View Customer Information',
  customerInformation: 'Customer Information',
  editInformation: 'Edit Information',
  noChangesHaveBeenMade: 'No changes have been made',
  showActiveCustomersOnly: 'Show Active Customers Only',
  showAllCustomers: 'Show All Customers',
  displayOptions: 'Display Options',
  orderTitle: 'Order Title',
  appointmentDisplayColor: 'Display Color',
  chooseHighlighterColor: 'Choose Highlighter Color',
  highlighterColorHint: "You must select a Highlighter Color",
  blue: 'Blue',
  green: 'Green',
  purple: 'Purple',
  orange: 'Orange',
  red: 'Red',
  gray: 'Gray',
  teal: 'Teal',
  salmon: 'Salmon',
  gold: 'Gold',
  multipleDayEvent: 'Multiple Days',
  startTime: 'Start Time',
  endTime: 'End Time',
  endTimeHint: 'End Time Must Be After Start Time',
  selectYear: 'Select Year',
  selectMonth: 'Select Month',
  selectDate: 'Select Date',
  startDate: 'Start Date',
  endDate: 'End Date',
  endDateHint: 'End Date must be after Start Date',
  selectTimePeriod: 'Select Time Period',
  selectHour: 'Select Hour',
  selectMinute: 'Select Minute',
  verifyId: 'Verify ID Document',
  scanQRCode: 'Scan QR Code to complete ID verification on your mobile device',
  errorVerifyingId: 'ID could not be verified',
  timeoutVerifyingId: 'Time expired waiting for ID Verification',
  awaitingIDPhoneVerification: 'Pending ID and Phone Number Verification',
  awaitingIDVerification: 'Pending ID Verification',
  awaitingPhoneVerification: 'Pending Phone Number Verification',
  awaitingSubmitVerification: 'Submit Your Certification Request',
  certificationComplete: 'Certification Complete',
  idDocument: 'ID Document',
  customerName: 'Customer Name',
  customerPhoneNumber: 'Customer Phone Number',
  customerEmail: 'Customer Email',
  customerNotes: 'Customer Notes',
  assignedTo: 'Assigned To',
  editService: 'Edit Service',
  uploadingFile: 'Uploading file...',
  techCerts: 'Tech Certifications',
  addShopTechCerts: 'Add Tech Certifications',
  addingShopTechCerts: 'Adding Tech Certifications',
  availableTechCertifications: 'Technician Certifications Available',
  numPurchasedTechCertifications: 'Technician Cerifications Purchased',
  technicianCertifications: 'Technician Certifications',
  changePayment: 'Change Payment Method',
  noPaymentMethodForIncreasingTechCerts: 'Shop Account has no payment method. Please add a payment method to increase number of tech certifications for your shop.',
  areYouSureYouWantToAddTechCerts: 'Are you sure you want to add <num> Tech Certifications to your Shop Account?',
  confirmAddTechCerts: 'Confirm Add Tech Certification(s) to Shop Account',
  noTechsAvailable: 'No Technician Certifications available',
  purchaseTechCerts: "You can purchase Tech Certifications here <shopProfileLink>.",
  eventHasBeenDeleted: 'This event has been deleted',
  deleteService: 'Do you want to delete this service?',
  serviceHasBeenDeleted: 'This service has been deleted',
  allDayEvent: 'All Day',
  threadCreated: 'Post created successfully',
  orderStatus: 'Order Status',
  scheduled: 'Scheduled',
  inProgress: 'In Progress',
  completed: 'Completed',
  canceled: 'Canceled',
  onHold: 'On Hold',
  delayed: 'Delayed',
  droppedOff: 'Dropped-off',
  scheduledHint: "Scheduled: Indicates that the event is planned for a future date and time",
  inProgressHint: "In Progress: Indicates that the event is currently being worked on",
  completedHint: "Completed: Indicates that the event has been successfully finished",
  canceledHint: "Canceled: Indicates that the event has been canceled before completion",
  onHoldHint: "On Hold: Indicates that the event has been started but it is temporarily paused",
  delayedHint: "Delayed: Indicates that there's a delay in starting or completing the event beyond the expected time",
  droppedOffHint: "Dropped-off: Indicates that the vehicle has been dropped off by the customer",
  lastUpdatedBy: 'Last Updated By',
  invalidVin: '"<vin>" is an invalid VIN',
  createdOn: 'Created On',
  listView: 'List View',
  gridView: 'Grid View',
  day: 'Day',
  week: 'Week',
  readyForPickUpNotified: 'The customer has been notified their vehicle is ready for pick up',
  notificationSent: 'Notification Sent',
  vehicleReadyForPickUp: 'Vehicle Ready for Pick Up',
  notifyIsReadyForPickUp: 'Notify Customer Vehicle Is Ready for Pick Up',
  pendingNotification: 'Pending Notification',
  pendingServiceApproval: 'Pending Service Approval',
  viewCustomerOrders: 'View Customer Orders',
  customerOrders: 'Customer Orders',
  customerServiceReport: 'Customer Service Report',
  oemsPerShop: 'Auto Manufacturers',
  addlOems: 'Add’l Auto Manufacturers',
  addlManPricing: "(<num> manufacturer included, $<price>/year add'l manufacturer)",
  noServiceCustomer: 'Customer has no service history',
  licensePlate: 'License Plate',
  crmSmsDisclaimer: 'Number can be used for text message',
  manageOrders: 'Manage Orders',
  createOrder: 'Create Order',
  searchOrder: 'Search Order',
  noOrders: 'No Orders',
  noCustomers: 'No Customers',
  manageVehicles: 'Manage Vehicles',
  addVehicle: 'Add Vehicle',
  vehicleAdded: 'Vehicle Added',
  searchVehicle: 'Search Vehicles',
  noVehicles: 'No Vehicles',
  appointments: 'Appointments',
  upcomingAppointments: 'Upcoming Appointments',
  order: 'Order',
  orders: 'Orders',
  orderStatus: 'Order Status',
  tomorrow: 'Tomorrow',
  technicians: 'Technicians',
  initiated: 'Initiated',
  invoiced: 'Invoiced',
  paid: 'Paid',
  readyForPickUp: 'Ready for Pick Up',
  nextWeek: 'Next Week',
  noAppointments: 'No Appointments',
  decodeVin: 'Decode VIN',
  make: 'Make',
  or: 'or',
  unlimitedUsers: 'Minimum - 200',
  unlimitedTools: 'Minimum - 200',
  openCustomerDetailPage: 'Open Customer Detail Page',
  customerDetails: 'Customer Details',
  vehicles: 'Vehicles',
  orders: 'Orders',
  orderOrders: 'Order(s)',
  customerAppointments: 'Customer Appointments',
  newServiceOrder: 'New Service Order',
  scheduleAppointment: 'Schedule Appointment',
  changeAppointment: 'Change Appointment',
  information: 'Information',
  removeVehicleFromCustomer: 'Remove Vehicle from Customer',
  confirmRemove: 'Are you sure you want to remove this vehicle from the customer?',
  customer: 'Customer',
  vehicleRemoved: 'Vehicle Removed',
  editVehicle: 'Edit Vehicle',
  odometer: 'Odometer',
  color: 'Color',
  vehicleUpdated: 'Vehicle Updated',
  sick: 'Sick',
  appointment: 'Appointment',
  holiday: 'Holiday',
  vacation: 'Vacation',
  deleting: 'Deleting',
  selectOrder: 'Select Order',
  scheduleEmployeeTimeOff: 'Schedule Employee Time Off',
  scheduleTimeOff: 'Schedule Time Off',
  changeEmployeeTimeOff: 'Change Employee Time Off',
  timeOff: 'Time Off',
  tileView: 'Tile View',
  yearMakeModel: 'Year/Make/Model',
  readyForPickup: 'Ready for Pickup',
  lookUp: 'Look Up',
  vehicleInfo: 'Vehicle Information',
  yearCapitalized: 'Year',
  deleteOrder: 'Do you want to delete this event?',
  newOrder: 'New Order',
  editOrder: 'Edit Order',
  createOrder: 'Create Order',
  orderNumber: 'Order Number',
  details: 'Details',
  orderDetails: 'Order Details',
  createNewOrder: 'Create New Order',
  relatedAppointments: 'Related Appointments',
  to: 'to',
  unavailable: 'unavailable',
  createVehicle: 'Create Vehicle',
  updateVehicle: 'Update Vehicle',
  newVehicleCreated: 'New Vehicle Created',
  vehicleUpdated: 'Vehicle Updated',
  subscriptions: 'Subscriptions',
  manageBilling: 'Manage Billing',
  only: 'only',
  savingOemRegions: 'Saving OEM Regions',
  nextSubAmount: 'Next <subscription> Payment Amount',
  signupForCRM: 'PLUS CRM Sign Up',
  crmPromoStatement: "Sign up for AutoAuth's new Customer Relationship Management System (CRM) and start managing customers, orders, and appointments today!",
  freeTrial: "Sign up today and get 90 days free!  $<price>/month billed monthly after the 90 days.",
  addedUserCRMCharge: "+ $<price>/user above the base PLUS users (<baseUsers>).",
  confirmCRMSignup: "Confirm Signup PLUS CRM",
  areYouSureYouWantToSignupCRM: "Are you sure you want to add PLUS CRM to your Shop Account?",
  youWillBeChargedToday: "You will be charged $<price> today and every consecutive month until you cancel.",
  afterFreeTrialYouWillBeCharged: "You will not be charged today.  You will be charged $<price> after 90 days (unless you cancel before that time) and every consecutive month your subscription is active.",
  crmUpchargeExplain: "PLUS CRM rate is $<base> base price plus $<upcharge> per added user/tool above base capacity.",
  crmCRMActivated: "",
  userCanceledSubscription: 'Subscription has been canceled.',
  reinstatingShop: "Reinstating Shop...",
  noPaymentMethodForCRMSignup:
    'Shop Account has no payment method. Please add a payment method in Manage Billing to sign up for PLUS CRM.',
  none: 'none',
  used: 'used',
  signUpNow: 'Sign Up Now',
  newAACRMServices: 'New AutoAuth PLUS CRM Services',
  shopCRMActive: 'PLUS CRM Subscription is Active! You have been charged $<price> today.',
  None: 'None',
  createCustomer: 'Create Customer',
  noTechnicianAssigned: 'No technician assigned',
  signUpToGetShopAndCustomerManagement: 'Sign up now and get access to shop and customer management services',
  upgradeAndSignUpForShopAndCustomerManagement: 'Upgrade to AutoAuth PLUS and sign up for access to shop and customer management services',
  gainAccessToCrucialServices: 'Gain access to crucial services to manage your shop operations with ease, including',
  customerDatabaseExplain: 'Customer database including vehicle information and work history',
  createCustomerOrdersExplain: 'Create and track customer orders',
  calendarOfSchedulesAndAppointmentsExplain: 'Calendar of employee schedules and shop appointments',
  automatedTextEmailNotificationsExplain: 'Automated text/email notifications to your customers and technicians',
  crmExplain: "Our Customer Relationship Management (CRM) software is the key to transforming how you connect with customers and manage your business.",
  upgradeToAutoAuthPlusCrm: 'Upgrade to AutoAuth PLUS CRM',
  thisOfferIsOnlyAvailableToPlus: 'PLUS CRM services are only available for AutoAuth PLUS shops.',
  crmUserUpchargeExplain: 'Additional Users above base capacity add $<upcharge> per added User to PLUS CRM subscription price',
  crmSubsccriptionReinstated: 'Your CRM Subscription has been reinstated.',
  confirmCancelCRMSubscription: 'Confirm Cancel PLUS CRM Subscription',
  confirmCancelCRMSubscriptionMessage:
    'Are you sure you want to cancel your PLUS CRM subscription? Doing so you will still be able to manage your shop customers, orders and appointments until your current month subscription expires. However, after that date you will no longer be able to do so.',
  confirmCancelFreeTrialCRMSubMessage:
    'Are you sure you want to cancel your PLUS CRM free trial? Doing so you will no longer be able to manage your shop customers, orders and appointments.',
  reinstatePlusCRM: 'Reinstate PLUS CRM',
  reinstatingPlusCRM: 'Reinstating PLUS CRM...',
  changePaymentInfo: 'Change Payment Method',
  addPayment: 'Add Payment Method',
  confirmCancelShopWithCRM: 'Additionally, you will be canceling your PLUS CRM subscription and will no longer be able to manage your shop customers, orders and appointments.',
  reinstateCRMDisableExplain: 'Shop must be PLUS level to reinstate CRM.',
  crmSubscriptionWillChange: 'Your PLUS CRM subscription price will also increase by $<upcharge> per additional user.',
  crmInitialSubcriptionCharge: 'PLUS CRM Initial Monthly Subscription Charge',
  crmSubscriptionRenewal: 'PLUS CRM Monthly Subscription Renewal',
  allDay: 'All Day',
  highlighterColor: 'Highlight Color',
  customerManagement: 'Customers Management',
  vehicleManagement: 'Vehicles Management',
  serviceOrdersManagement: 'Service Orders Management',
  appointmentCalendar: 'Appointment Calendar',
  afterFreeTrial: 'after 90 day free trial',
  choosePlusCrm: 'Choose AutoAuth PLUS CRM',
  crmFee: 'CRM fee',
  autoAuthPlusCRMOption: '<AutoAuthStandardPlusCRM> Recommended authenticated diagnostic service contains detailed reports and statistics plus CRM services including customer, vehicle and service order management.',
  crmAddon: 'CRM Addon',
  chargedAfterFreeTrial: 'Note: You will be charged after 90 day free trial.',
  areYouSureUpgradeShopType: 'Are you sure you want to upgrade to <shopType>?',
  crmChargeExplainedFreeTrial: 'After your 90 free trial you will be charged $<price> each month the PLUS CRM subscription is active.',
  crmChargeExplained: 'You will be charged $<price> today and each additional MONTH the PLUS CRM subscription is active.',
  addingCustomer: 'Adding customer...',
  enterValidVehicleYear: 'Please enter a valid vehicle year',
  removeAppointment: 'Remove Appointment',
  confirmRemoveAppointment: 'Are you sure you want to remove this appointment? This action cannot be undone.',
  removeTimeOff: 'Remove Time Off',
  confirmRemoveTimeOff: 'Are you sure you want to remove this time off? This action cannot be undone.',
  appointmentRemoved: 'Appointment Removed',
  timeOffRemoved: 'Time Off Removed',
  upgradeNowAndManageCustomerAppointments: 'Upgrade now and manage customer appointments and technician schedules in one place.',
  upgradeAndSignUpAndUnlockBenefits: 'Upgrade to AutoAuth PLUS CRM and unlock the benefits of our integrated shop calendar',
  signUpAndUnlockBenefits: 'Sign up for PLUS CRM and unlock the benefits of our integrated shop calendar',
  leverageTheAutoAuthPlusCrmCalendar: 'Leverage our new PLUS CRM calendar to enhance your shop operations, including',
  manageAppointmentsAndTimeOffExplain: 'Manage customer appointments and employee time off in one convenient location',
  gainValuableInsightsIntoYourShopsBusiestTimes: "Gain valuable insights into your shop's busiest times to optomize staff and shop bays accordingly",
  sendAutomatedTextEmailNotificationsExplain: 'Send automated text/email notifications and reminders to your customers and technicians',
  seeOverViewOfScheduleExplain: "Easily view your shop's schedule with flexible day, week, and month views all at a glance",
  upgradeNowAndManageServiceOrders: "Upgrade now and manage your shop's service orders with ease.",
  signUpAndStreamlineYourShopOperations: "Sign up for PLUS CRM and streamline your shop operations with dynamic service order management.",
  upgradeToAutoAuthPlusCrmAndStreamline: "Upgrade to AutoAuth PLUS CRM and streamline your shop operations with dynamic service order management.",
  enhanceShopsEfficiencyWithOrderManagementExplain: "Enhance your shop's efficiency with our new service order management service, offering",
  allServiceOrdersAtAGlanceExplain: "All your service orders at a glance, giving you a bird's-eye perspective of your shop's operations.",
  quicklyCreateAndLogOrdersExplain: "Quickly create and log orders in seconds while you're on the phone with a customer.",
  convenientlyTrackStatusOfOrdersExplain: "Conveniently track the status of every order and keep track of technicians' tasks.",
  attachServiceOrdersToCalendarExplain: "Attach service orders to your shop's calendar appointments.",
  upgradeNowAndAccessCustomerDatabase: "Upgrade now and manage your customers in one convenient table.",
  upgradeNowAndStoreCustomerVehicles: "Upgrade now and manage your customers' vehicles in one convenient table.",
  plusCrmFeatures: 'AutoAuth PLUS CRM Features',
  homePageCrmNoticeExplain: "All the advantages of AutoAuth PLUS, plus additional Customer Relationship Management features.",
  plusCurrentPlan: "AutoAuth PLUS (Current Plan)",
  signUpForPlusCrmAndGetAccessToCustomerDirectory: "Sign up for PLUS CRM and get access to our new customer directory service.",
  upgradeAndSignUpForPlusCrmAndGetAccessToCustomerDirectory: "Upgrade to AutoAuth PLUS CRM and get access to our new customer directory service.",
  keepInfoOrganizedWithCustomerDirectory: "Keep your information organized with our new customer directory, which allows you to",
  addNewCustomersExplain: "Add new customers and their vehicles to store them in one convenient location.",
  findCustomerContactInfoExplain: "Quickly find your customers' contact information.",
  referenceCustomersServiceHistoryExplain: "Reference customers' service history with your shop, including service orders that were canceled.",
  attachCustomerToAppointmentExplain: "Attach any customer to an appointment or service order for improved efficiency.",
  sendTextEmailRemindersExplain: "Send text/email reminders to your customers to remind them of their upcoming appointments.",
  signUpForPlusCrmAndGetAccessToVehicleDirectory: "Sign up for PLUS CRM and get access to our new vehicle directory service.",
  upgradeAndSignUpForPlusCrmAndGetAccessToVehicleDirectory: "Upgrade to AutoAuth PLUS CRM and get access to our new vehicle directory service.",
  keepInfoOrganizedWithVehicleDirectory: "Keep your information organized with our new vehicle directory, which allows you to",
  addNewVehiclesExplain: "Add new vehicles to store them in one convenient location.",
  quicklyGlanceAtVehicleInfoExplain: "Quickly glance at information relating to vehicles that have been serviced by your shop.",
  editVehicleInfoExplain: "Edit information regarding vehicles that have been serviced by your shop.",
  activeShopRequired: "Active AutoAuth PLUS Required",
  activeShopRequiredForCRMSignup: "Active AutoAuth PLUS subscription required to sign up for PLUS CRM.  Reinstate your subscription in order to sign up for PLUS CRM.",
  activeShopRequiredForUpgrade: "Active AutoAuth subscription required to upgrade to PLUS or PLUS CRM.  Reinstate your subscription in order upgrade.",
  activeShopRequiredTitle: 'Active AuthAuth Subscription Required',
  sendCustomerNotification: 'Send Customer Notification',
  confirmCustomerNotificationMessage: "Would you like to send 'Ready for Pickup' message to your customer using their preferred contact method?",
  send: 'Send',
  dontSend: "Don't Send",
  qty: 'Qty',
  successfullyUpgradedToPlus: 'You have successfully upgraded to AutoAuth PLUS! You have been charged $<price> today.',
  noMatchesFound: 'No Matches Found',
  techUpgradeToStandardPlusCRM: 'Ask your Shop Owner to upgrade to AutoAuth PLUS CRM',
  updatePaymentMethodToReinstate: 'Payment method must be updated on the Manage Billing tab to reinstate your shop',
  transactionNotFound: 'Transaction not found.',
  clockSyncError: "Your computer time is too far off from server time.  Adjust your computer clock and try again.",
  missingIncorrectFields: "Missing/Incorrect fields",
  removeCustomer: "Remove Customer",
  confirmRemoveCustomer: 'Are you sure you want to remove this customer?',
  customerRemoved: 'Customer Removed',
  userChangeUserShopRole: "User changed user shop role",
  customers: 'Customers',
  tools: 'Tools',
} // Dont use semicolon, backslash \, backticks ``, or concatenation 'stringA' + 'stringB'