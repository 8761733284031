import React from 'react';
import Header from '../../../components/Header';
import { TextOnly } from '../../../components/Text';
import ManageCustomersPromo from './ManageCustomersPromo';
import ManageVehiclesPromo from './ManageVehiclesPromo';
import { ChildProps } from '../../../types';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tab';

interface CustomersPromoProps extends ChildProps {
  history: any;
}

const CustomersPromo: React.FC<CustomersPromoProps> = (props) => {
  const { history } = props;

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('customers')} />
      <div className="c-box--white u-padding-none">
        <Tabs
          defaultActiveKey='customers'
        >
          <Tab
            className="tab-shop-profile"
            eventKey={'customers'}
            label={TextOnly('customers')}
          >
            <ManageCustomersPromo
              history={history}
            />
          </Tab>
          <Tab
            eventKey={'vehicles'}
            label={TextOnly('vehicles')}
            className="tab-shop-profile"
          >
            <ManageVehiclesPromo
              history={history}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default CustomersPromo;