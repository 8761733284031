import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/aa.svg';
import logoPlus from '../assets/images/aa-logo-plus.svg';
import logoPlusLock from '../assets/images/lock-logo-plus.png';
import logoPlusCRM from '../assets/images/aa-logo-pluscrm.svg';
import logoPlusCRMLock from '../assets/images/lock-logo-crm.png';
import logoPro from '../assets/images/aa-pro.svg';
import logoProLock from '../assets/images/lock-logo-pro.png';
import logoStandard from '../assets/images/aa-standard.svg';
import logoStandardLock from '../assets/images/lock-logo-standard.png';
import { Text, TextOnly } from '../components/Text';
import { ShopType, ShopRole, User } from '../types';
import { SHOP_TYPES, SHOP_ROLES } from '../CONSTANTS';
import SidebarNavLink from './SidebarNavLink';

import { ReactComponent as KeyIcon } from '../assets/icons/file-text.svg';
import config from '../config';

interface SidebarProps {
  isAuthenticated: boolean;
  logout: () => void;
  toggleShowMobileNav: () => void;
  user: User;
  mbRole: string;
  shopName: string;
  shopRole: ShopRole;
  shopType: ShopType;
  gotBanner: number;
  certifiedTech: boolean;
  crmState?: string;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const {
    isAuthenticated,
    logout,
    toggleShowMobileNav,
    shopRole,
    shopType,
    user,
    mbRole,
    shopName,
    gotBanner,
    certifiedTech,
    crmState,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [animClass, setAnimClass] = useState('');

  const currentPath = window.location.pathname;

  const sidebarTextClasses = `sidebar-text ${isCollapsed ? 'hidden' : ''}`;

  const hasShopType = typeof shopType === 'string';

  const isUserOfNonCRMShop =
    hasShopType && shopRole && shopRole === SHOP_ROLES.TECH
    && crmState !== "ACTIVE";

  const isOwner = shopRole && shopRole === SHOP_ROLES.OWNER;

  const handleLogout = () => {
    setIsCollapsed(false);
    setAnimClass('');
    logout();
  }

  const handleChangeCollapsed = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    setAnimClass(newIsCollapsed ? 'collapsed' : 'expanded');
  }

  const findLogoImg = (size: 'small' | 'large') => {
    const isSmall = size === 'small';
    let logoImg = logo;

    if (shopType === SHOP_TYPES.ENTERPRISE) {
      logoImg = isSmall ? logoProLock : logoPro;
    } else if (shopType === SHOP_TYPES.PLUS) {
      if (crmState === 'ACTIVE') {
        logoImg = isSmall ? logoPlusCRMLock : logoPlusCRM;
      } else {
        logoImg = isSmall ? logoPlusLock : logoPlus;
      }
    } else if (shopType === SHOP_TYPES.STANDARD) {
      logoImg = isSmall ? logoStandardLock : logoStandard;
    }

    return logoImg;
  }

  const logoImg = findLogoImg('large');
  const logoImgSmall = findLogoImg('small');

  return !isAuthenticated || !user?.userName ? (
    <div className="c-sidebar">
      <div className="c-sidebar__header c-sidebar__header--empty">
        <NavLink
          className="c-primary-nav__link"
          to="/"
          onClick={toggleShowMobileNav}
        >
          <div className="c-logo">
            <img src={logo} className="c-logo__image" alt="logo" />
          </div>
        </NavLink>
        <p className="u-font-weight-500">
          <Text tid="tagLine" />
        </p>
      </div>
    </div>

  ) : user ? (
    <div className={`c-sidebar ${animClass}`}>
      {!toggleShowMobileNav &&
        <button
          className="collapse-btn"
          onClick={handleChangeCollapsed}
        >
          <div className={`collapse-btn__inner ${isCollapsed ? 'flipped' : ''}`}>
            <i className="collapse-btn__icon fa-solid fa-chevron-left" />
          </div>
        </button>
      }
      <div className={`c-sidebar__header ${isCollapsed ? 'collapsed' : ''}`}>
        <button
          className="c-btn-icon c-btn-mobile-close"
          onClick={toggleShowMobileNav}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <NavLink
          className="c-primary-nav__link"
          to="/myActivity"
          onClick={toggleShowMobileNav}
        >
          <div className={`c-logo--sidebar-large ${animClass}`}>
            <img src={logoImg} className="c-logo__image" alt="logo" />
          </div>
          <div className={`c-logo--sidebar-small ${animClass}`}>
            <img src={logoImgSmall} className="c-logo__image" alt="logo" />
          </div>
        </NavLink>
        {config.CERTIFIED_TECH_STATE === "ACTIVE" && certifiedTech ?
          <div className="c-logo cert-tech-padding">
            <img src={'/AA-cert-tech.png'} className="c-logo__image" alt="AutoAuth Cerified Technician" />
          </div>
        : '' }
      </div>

      <div className={"c-sidebar__main"+(config.CERTIFIED_TECH_STATE === "ACTIVE" && certifiedTech ? ' with-cert-tech' : '')}>
        <div>
          <nav className="c-primary-nav" role="navigation">
            <ul className={`c-primary-nav__list ${isCollapsed ? 'collapsed' : ''}`}>
              <SidebarNavLink
                isCollapsed={isCollapsed}
                pageTextId='myActivity'
                onClick={toggleShowMobileNav}
              />
              { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <SidebarNavLink
                  isCollapsed={isCollapsed}
                  pageTextId='tools'
                  onClick={toggleShowMobileNav}
                />
              }
              { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <SidebarNavLink
                  isCollapsed={isCollapsed}
                  pageTextId='shopUsers'
                  onClick={toggleShowMobileNav}
                />
              }
              { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <SidebarNavLink
                  isCollapsed={isCollapsed}
                  pageTextId='shopProfile'
                  onClick={toggleShowMobileNav}
                />
              }
              { (config?.CRM_STATE === "ACTIVE"
                  && hasShopType
                  && shopType !== "ENTERPRISE"
                  && user.shopUserState === "ACTIVE"
                  && !isUserOfNonCRMShop) &&
                  <>
                    <SidebarNavLink
                      isCollapsed={isCollapsed}
                      pageTextId='orders'
                      onClick={toggleShowMobileNav}
                    />
                    <SidebarNavLink
                      isCollapsed={isCollapsed}
                      pageTextId='customers'
                      onClick={toggleShowMobileNav}
                    />
                    <SidebarNavLink
                      isCollapsed={isCollapsed}
                      pageTextId='calendar'
                      onClick={toggleShowMobileNav}
                    />
                  </>
              }
              { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <SidebarNavLink
                  isCollapsed={isCollapsed}
                  pageTextId='reports'
                  onClick={toggleShowMobileNav}
                />
              }
              <SidebarNavLink
                isCollapsed={isCollapsed}
                pageTextId='myProfile'
                onClick={toggleShowMobileNav}
              />
              { (config.OWNER_VERIFICATION_STATE === "ACTIVE" &&
                shopType !== "ENTERPRISE" &&
                shopRole &&
                (shopType === "PLUS" || shopRole !== SHOP_ROLES.TECH)) &&
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={`c-primary-nav__link background-anim ${!isCollapsed ? 'u-width-100-percent' : 'expand-on-hover'} ${
                        (shopType !== SHOP_TYPES.PLUS)
                          ? 'c-primary-nav__link--plus-promo-2'
                          : !user?.certifiedTech ?
                          'c-primary-nav__link--certify-promo' : ''
                      }`}
                      to={
                        shopType !== SHOP_TYPES.PLUS
                          ? '/vehicleOwnerVerificationPromo'
                          : !user?.certifiedTech ?
                              "/vehicleOwnerVerificationVerifyIdentity"
                            : "/vehicleOwnerVerification"
                      }
                      activeClassName="is-active"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <KeyIcon
                          className={`c-primary-nav__icon ${isCollapsed ? 'u-margin-none' : ''}`}
                          title={TextOnly('vehicleOwnerVerification')}
                        />
                        <span className={sidebarTextClasses}>
                          <Text tid="vehicleOwnerVerification" />
                        </span>
                      </div>
                    </NavLink>
                  </li>
              }
              { config.MESSAGE_BOARD_STATE === "ACTIVE" && shopType ?
                <SidebarNavLink
                  isCollapsed={isCollapsed}
                  pageTextId='messageBoard'
                  onClick={toggleShowMobileNav}
                />
              : '' }
            </ul>
          </nav>
        </div>
        <ul className={`c-sidebar__links ${isCollapsed ? 'collapsed' : ''}`}>
          {shopType === SHOP_TYPES.STANDARD && isOwner ? (
            <SidebarNavLink
              isCollapsed={isCollapsed}
              pageTextId="comparePlans"
              onClick={toggleShowMobileNav}
            />
          ) : null}
          <SidebarNavLink
            isCollapsed={isCollapsed}
            pageTextId="logout"
            onClick={handleLogout}
          />
          <SidebarNavLink
            isCollapsed={isCollapsed}
            pageTextId="support"
          />
        </ul>
      </div>
    </div>
  ) : (
    <div className="c-sidebar">{/* empty sidebar */}</div>
  );
};

export default Sidebar;
