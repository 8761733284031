import React from 'react';
import Header from '../components/Header';
import { TextOnly } from '../components/Text';
import Tabs from '../components/Tabs';
import Tab from '../components/Tab';
import { ShopActions } from './ShopActions';
import { ToolActions } from './ToolActions';
import { StatsAndTrends } from './StatsAndTrends';


const Reports: React.FC<any> = (props) => {
  const { currentShop, user, history, lang } = props;

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('reports')} />
      <div className="c-box--white u-padding-none">
        <Tabs
          defaultActiveKey='statsAndTrends'
        >
          <Tab
            className="tab-shop-profile"
            eventKey={'statsAndTrends'}
            label={TextOnly('statsAndTrends')}
          >
            <StatsAndTrends
              currentShop={currentShop}
              history={history}
            />
          </Tab>
          <Tab
            eventKey={'shopActions'}
            label={TextOnly('shopActions')}
            className="tab-shop-profile"
          >
            <ShopActions
              currentShop={currentShop}
              history={history}
              lang={lang}
              user={user}
            />
          </Tab>
          <Tab
            eventKey={'toolActions'}
            label={TextOnly('toolActions')}
            className="tab-shop-profile"
          >
            <ToolActions
              currentShop={currentShop}
              user={user}
              history={history}
              lang={lang}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Reports;