import React from 'react';
import { Tooltip, Position } from '@reach/tooltip';

interface SidebarTooltipProps {
  label: string | JSX.Element;
  additionalStyles?: object;
  onClick?: () => void;
  children?: JSX.Element;
}

const SidebarTooltip = ({
  label,
  additionalStyles,
  onClick,
  children,
}: SidebarTooltipProps) => {

  // Function to position the tooltip on the right side of the target element
  const positionToRight: Position = (
    triggerRect: Partial<DOMRect> | null | undefined,
    tooltipRect: Partial<DOMRect> | null | undefined
  ): React.CSSProperties => {
    // Handle edge cases where the rectangles are not available
    if (
      !triggerRect ||
      !tooltipRect ||
      !triggerRect.right ||
      !triggerRect.top ||
      !triggerRect.height ||
      !tooltipRect.height
    ) {
      return {};
    }

    // Calculate positions for tooltip alignment
    const left = triggerRect.right + window.scrollX;
    const top =
      triggerRect.top +
      (triggerRect.height - tooltipRect.height) / 2 +
      window.scrollY;

    return {
      left,
      top,
    };
  };

  return (
    <Tooltip
      label={label}
      style={{
        ...additionalStyles,
        background: '#fff',
        padding: '0.5rem 1rem',
        margin: '0 0 0 15px',
        opacity: '1',
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(138, 148, 153, 0.2), 0 6px 20px 0 rgba(138, 148, 153, 0.19)',
        border: '1px solid #fff',
        color: '#556066',
        whiteSpace: 'normal',
        maxWidth: 'fit-content',
      }}
      position={positionToRight}
    >
      {children || (
        <div
          className="c-btn__inner u-margin-left u-cursor-pointer"
          style={{ width: 'fit-content' }}
          onClick={onClick && onClick}
        >
          <span className="c-btn__icon fa fa-question-circle" />
        </div>
      )}
    </Tooltip>
  );
};

export default SidebarTooltip;