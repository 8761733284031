export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  AUTH_DIAG_ENVIRONMENT: "STAGE",
  MESSAGE_BOARD_STATE: "ACTIVE",
  CERTIFIED_TECH_STATE: "INACTIVE",
  OWNER_VERIFICATION_STATE: "INACTIVE",
  CRM_STATE: "ACTIVE",
  STRIPE_PUBLIC_KEY: "pk_live_51NyJtqD9mKfN0INjbodoUkFTp2l14aCPeir2BtOPrndm8W8EGgWdhuX0aHTuZsDdDZN9tF2cYLztXgrXpMCWMCbb00IsBGKrn2",
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://544oke2x13.execute-api.us-west-2.amazonaws.com/dev",
    MB_URL: "https://lu8g6ts0xd.execute-api.us-west-2.amazonaws.com/dev",
    CRM_URL: "https://tpa5amo03a.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_6VaylLYR7",
    APP_CLIENT_ID: "7ua8089bras834svmpuo1plrqc",
    DOMAIN: "https://staging-autoauth.auth.us-west-2.amazoncognito.com",
    IDENTITY_POOL_ID: "us-west-2:292de583-c18c-4247-9545-1932b2734678"
  }
};
