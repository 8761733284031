import React from 'react';
import Header from '../components/Header';
import { TextOnly } from '../components/Text';
import Tabs from '../components/Tabs';
import Tab from '../components/Tab';
import ShopReportsPromo from './ShopReportsPromo';
import ToolReportsPromo from './ToolReportsPromo';
import StatsAndTrendsPromo from './StatsAndTrendsPromo';


const ReportsPromo: React.FC<any> = (props) => {
  const { isAuthenticated, history } = props;

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('reports')} />
      <div className="c-box--white u-padding-none">
        <Tabs
          defaultActiveKey='statsAndTrends'
        >
          <Tab
            className="tab-shop-profile"
            eventKey={'statsAndTrends'}
            label={TextOnly('statsAndTrends')}
          >
            <StatsAndTrendsPromo
              isAuthenticated={isAuthenticated}
              history={history}
            />
          </Tab>
          <Tab
            eventKey={'shopActions'}
            label={TextOnly('shopActions')}
            className="tab-shop-profile"
          >
            <ShopReportsPromo />
          </Tab>
          <Tab
            eventKey={'toolActions'}
            label={TextOnly('toolActions')}
            className="tab-shop-profile"
          >
            <ToolReportsPromo />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default ReportsPromo;