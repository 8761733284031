import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import { TextOnly } from '../../components/Text';
import { crmGetShopCustomers } from '../../libs/db-lib';
import { Loading } from '../../components/Loading';
import VehicleTable from './ManageVehicles/VehicleTable';
import CustomerTable from './ManageCustomers/CustomerTable';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';


const Customers: React.FC<any> = (props) => {
  const { currentShop, user } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);

  const getAllCustomersVehicles = async () => {
    const response: any = await crmGetShopCustomers(currentShop.shopID);
    if (!response?.error) {
      const allVehiclesList: any[] = [];
      response.forEach((customer: any) => {
        customer.filterValue = Object.values(customer)
          .join(' ')
          .toLocaleLowerCase();
        customer.vehicles?.forEach((vehicle: any) => {
          allVehiclesList.push({
            ...vehicle,
            customerID: customer.customerID,
          });
        })
      });
      setCustomers(response);
      setVehicles(allVehiclesList);
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
  };

  const updateSingleCustomer = (updatedCustomer: any, newVehicle?: any, action?: string) => {
    let newCustomer = updatedCustomer;

    //Updating customer vehicle
    if (newVehicle && action) {
      let newCustomerVehicles;
      const customer = updatedCustomer
      if (action === 'ADD') {
        newCustomerVehicles = customer.vehicles;
        newCustomerVehicles.push(newVehicle);
      } else if (action === 'UPDATE') {
        newCustomerVehicles = customer.vehicles.map((vehicle: any) => {
          if (vehicle.vehicleID !== newVehicle.vehicleID) {
            return vehicle;
          } else {
            return newVehicle;
          }
        });
      } else /* if (action === 'REMOVE') */ {
        newCustomerVehicles = customer.vehicles.filter((vehicle: any) =>
          vehicle.vehicleID !== newVehicle.vehicleID
        );
      }
      customer.vehicles = newCustomerVehicles;
      newCustomer = customer;
    }

    //Updating customer information
    const updatedCustomers = customers.map( cust =>{
      if (cust.customerID === newCustomer.customerID) {
        return {...cust, ...newCustomer}
      } else {
        return cust;
      }
    })
    setCustomers(updatedCustomers);

    //Updating vehicle information
    const updatedVehicles = action === 'ADD'
      ? [{ ...newVehicle,
          customerID: updatedCustomer.customerID
        }, ...vehicles]
      : vehicles.map((vehicle: any) => {
        if (vehicle.vehicleID === newVehicle.vehicleID){
          return { ...vehicle, ...newVehicle }
        } else {
          return vehicle;
        }
      });
    setVehicles(updatedVehicles);
  };

  const addNewCustomer = (customer: any) => {
    customer.filterValue = Object.values(customer)
      .join(' ')
      .toLocaleLowerCase();
    setCustomers([customer, ...customers]);

    const newVehicles: any[] = [];
    customer.vehicles?.forEach((vehicle: any) => {
      newVehicles.push({
        ...vehicle,
        customerID: customer.customerID,
      })
    })
    if (newVehicles.length) {
      setVehicles([...newVehicles, ...vehicles])
    }
  }

  useEffect(() => {
    const onMount = async () => {
      await getAllCustomersVehicles();
      setIsLoading(false);
    }
    onMount();
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('customers')} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="c-box--white u-padding-none">
          <Tabs
            defaultActiveKey='customers'
          >
            <Tab
              className="tab-shop-profile"
              eventKey={'customers'}
              label={TextOnly('customers')}
            >
              <CustomerTable
                addNewCustomer={addNewCustomer}
                allCustomers={customers}
                currentShop={currentShop}
                user={user}
                history={props.history}
                onChange={updateSingleCustomer}
              />
            </Tab>
            <Tab
              eventKey={'vehicles'}
              label={TextOnly('vehicles')}
              className="tab-shop-profile"
            >
              <VehicleTable
                handleVehicleChange={getAllCustomersVehicles}
                allCustomers={customers}
                vehicles={vehicles}
                currentShop={currentShop}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default Customers;