import React from 'react';
import SidebarTooltip from '../components/SidebarTooltip';
import { NavLink } from 'react-router-dom';
import { TextOnly, Text } from '../components/Text';
import { SHOP_TYPES } from '../CONSTANTS';
import { useUser } from '../context/User';
// nav icons
import { ReactComponent as MyActivityIcon } from '../assets/icons/nav-dashboard.svg';
import { ReactComponent as ManageUsersIcon } from '../assets/icons/nav-manage-users.svg';
import { ReactComponent as ManageToolsIcon } from '../assets/icons/nav-manage-tools.svg';
import { ReactComponent as ViewReportsIcon } from '../assets/icons/nav-view-reports.svg';
import { ReactComponent as ShopProfileIcon } from '../assets/icons/nav-shop-profile.svg';
import { ReactComponent as UserProfileIcon } from '../assets/icons/nav-user-profile.svg';
import { ReactComponent as MessageBoardIcon } from '../assets/icons/chat.svg';
import { ReactComponent as FileInvoiceIcon } from '../assets/icons/my-file-invoice.svg';
import { ReactComponent as MyCalendarIcon } from '../assets/icons/my-calendar-days.svg';
import { ReactComponent as CustomersIcon } from '../assets/icons/people-group.svg';


interface SidebarNavLinkProps {
  isCollapsed: boolean;
  pageTextId: 'myActivity' | 'tools' | 'shopUsers' | 'shopProfile' |
    'orders' | 'customers' | 'calendar' | 'reports' | 'myProfile' |
    'messageBoard' | 'comparePlans' | 'logout' | 'support';
  onClick?: () => void;
}

const SidebarNavLink: React.FC<SidebarNavLinkProps> = ({
  isCollapsed,
  pageTextId,
  onClick
 }) => {

  const { currentShop } = useUser();
  const crmState = currentShop?.crmState;
  const shopType = currentShop?.shopType;
  const navIconClasses = `c-primary-nav__icon ${isCollapsed ? 'u-margin-none' : ''}`;
  const cBtnIconClasses = `c-btn__icon ${isCollapsed && 'minimal'}`;
  let navLinkClasses = `c-primary-nav__link background-anim ${!isCollapsed ? 'u-width-100-percent' : 'expand-on-hover'}`;
  let url;
  let icon;

  switch (pageTextId) {
    case 'myActivity':
      url = "/myActivity";
      icon =  <MyActivityIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'tools':
      url = "/manageTools/manageShopTools";
      icon =  <ManageToolsIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'shopUsers':
      url = "/manageUsers/currentUsers";
      icon =  <ManageUsersIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'shopProfile':
      url = "/shopProfile";
      icon =  <ShopProfileIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'orders':
      navLinkClasses += crmState !== 'ACTIVE'
        ? ` c-primary-nav__link--plus-crm-promo ${isCollapsed ? 'hidden' : ''}`
        : '';
      url = crmState !== "ACTIVE"
        ? '/upgradeToPlusCRM/ManageOrdersPromo'
        : '/crm/manageOrders';
      icon =  <FileInvoiceIcon
                className={`${navIconClasses} fa-invoice`}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'customers':
      navLinkClasses += crmState !== 'ACTIVE'
        ? ` c-primary-nav__link--plus-crm-promo ${isCollapsed ? 'hidden' : ''}`
        : '';
      url = crmState !== "ACTIVE"
        ? '/upgradeToPlusCRM/CustomersPromo'
        : '/crm/customers';
      icon =  <CustomersIcon
                className={`${navIconClasses} fa-customers`}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'calendar':
      navLinkClasses += crmState !== 'ACTIVE'
        ? ` c-primary-nav__link--plus-crm-promo ${isCollapsed ? 'hidden' : ''}`
        : '';
      url = crmState !== 'ACTIVE'
        ? '/upgradeToPlusCRM/EventManagementPromo'
        : '/crm/calendar';
      icon =  <MyCalendarIcon
                className={`${navIconClasses} fa-calendar`}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'reports':
      navLinkClasses += shopType === SHOP_TYPES.STANDARD
        ? ` c-primary-nav__link--plus-promo ${isCollapsed ? 'hidden' : ''}`
        : '';
      url = shopType === SHOP_TYPES.STANDARD
      ? '/upgradeToPlus/reportsPromo'
      : '/reports';
      icon =  <ViewReportsIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'myProfile':
      url = "/userProfile";
      icon =  <UserProfileIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'messageBoard':
      url = "/messageBoard";
      icon =  <MessageBoardIcon
                className={navIconClasses}
                title={TextOnly(pageTextId)}
              />;
      break;

    case 'comparePlans':
      navLinkClasses = `c-sidebar__link c-btn-dark ${isCollapsed ? 'collapsed' : ''}`;
      url = "/upgradeToPlus/comparePlans";
      icon = <i className={`${cBtnIconClasses} fa-light fa-scale-unbalanced-flip`}></i>;
      break;

    case 'logout':
      navLinkClasses = `c-sidebar__link c-btn-light ${isCollapsed ? 'collapsed' : ''}`;
      icon = <i className={`${cBtnIconClasses} fal fa-sign-out`} />;
      break;

    case 'support':
      navLinkClasses = `c-sidebar__link c-btn-light ${isCollapsed ? 'collapsed' : ''}`;
      icon = <i className={`${cBtnIconClasses} fal fa-question-circle`} />;
      break;
  }

  const navItem =
    pageTextId === 'logout' ? (
      <li>
        <button className={navLinkClasses} onClick={onClick}>
          <div className="c-btn__inner">
            {icon}
            <span className={`sidebar-text ${isCollapsed ? 'hidden' : ''}`}>
              <Text tid={pageTextId} />
            </span>
          </div>
        </button>
      </li>
    ) : pageTextId === 'support' ? (
      <li>
        <a
          className={navLinkClasses}
          href="https://info.autoauth.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          <div className="c-btn__inner">
            {icon}
            <span className={`sidebar-text ${isCollapsed ? 'hidden' : ''}`}>
              <Text tid={pageTextId} />
            </span>
          </div>
        </a>
      </li>
    ) : (
      <li className={pageTextId === 'comparePlans' ? '' : "c-primary-nav__item"}>
        <NavLink
          className={navLinkClasses}
          to={url}
          activeClassName="is-active"
          onClick={onClick}
        >
          <div className="c-btn__inner">
              {icon}
            <span className={`sidebar-text ${isCollapsed ? 'hidden' : ''}`}>
              <Text tid={pageTextId} />
            </span>
          </div>
          {navLinkClasses.includes('plus-crm-promo') ? (
            <div className={`plus-crm-tag ${isCollapsed ? 'hidden' : ''}`}>PLUS CRM</div>
          ) : navLinkClasses.includes('plus-promo') ? (
            <div className={`plus-tag ${isCollapsed ? 'hidden' : ''}`}>PLUS</div>
          ) : (
            ''
          )}
        </NavLink>
      </li>
    );

  return isCollapsed ? (
    <SidebarTooltip
      label={TextOnly(pageTextId)}
    >
      {navItem}
    </SidebarTooltip>
  ) : navItem;
};

export default SidebarNavLink;